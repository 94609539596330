import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import { blogFormImpl } from "data/blog/blogForm/blogFormImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Blogs;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getBlogs() {
        let result = await axiosCall(ApiRouteMapping.getBlogs.apiPath, "GET");
        const blogs = new blogFormImpl();
        blogs.initFromDataObject(result.data.blogs.blogs);
        return blogs;
    }
}