import axiosCall from 'lib/axios';


import pageSEOImpl from 'data/SEO/pageSEO/pageSEOImpl';

import ApiRouteMapping from 'utils/AllApiRouteMapping';
import PageSEOData from 'utils/AllPageSEOData';
import helperFunctionsImpl from 'utils/helperFunctionsUtils/helperFunctionsImpl';


class functions {

    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Design;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl())
        return pageSEO;
    }

    async getNextjsComponent() {
        let result = await axiosCall(ApiRouteMapping.nextjsGetDesigns.apiPath, "GET");

        return result.data.designs.designs;
    }
    

}
 
export default functions;