import React, { Component } from 'react'
import Input from 'components/InputGroup/Input';
import Button from 'components/Button/Button';
import TinyMCEEditor from 'lib/TinyMCEEditor';
import functions from 'pages/AddConverter/functions';
import ConverterInterface from 'data/converter/ConverterInterface';


interface state{
    converterDetails: ConverterInterface
}

export default class AddConverter extends Component<any, state> {
    constructor(props){
        super(props);

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            converterDetails: {} as ConverterInterface
        }
    }
    onChangeHandler(e, editorName) {
        let name = '';
        let value = '';
        if (editorName === 'description') {
            name = editorName;
            value = e;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        this.setState((prevState) => ({
            converterDetails: {
                ...prevState.converterDetails,
                [name]: value,
            },
        }));
    }

    async onSubmit(e) {
        e.preventDefault();

        const AddConverterHandler = new functions();
        const result = await AddConverterHandler.addConverter(this.state.converterDetails)

        if(result.success === true){
            alert("Converter Added Successfully !");
        }
        else{
            alert("Try Again !");
            window.location.reload();
        }
    }
    render() {
        return (
        <>
            <div className="ac-container" style={{width: '50%', margin: 'auto', padding: '2rem 0rem'}}>
                <form onSubmit={this.onSubmit}>
                    <Input
                        label="Converter Name"
                        name="converterName"
                        onChange={this.onChangeHandler}
                        required = {1}
                    />

                    <Input
                        label="From"
                        name="from"
                        onChange={this.onChangeHandler}
                        required = {1}
                    />

                    <Input
                        label="To"
                        name="to"
                        onChange={this.onChangeHandler}
                        required = {1}
                    />

                    <Input
                        label="Description"
                    >
                        <TinyMCEEditor 
                            onEditorChange = {(e) => this.onChangeHandler(e, 'description')}
                            textareaName = "description"
                        />
                    </Input>

                    <Input
                        label="Tags"
                        name="tags"
                        onChange={this.onChangeHandler}
                        placeholder={'HTML,CSS,JS'}
                        required={1}
                    />

                    <Button
                        id={undefined}
                        text="Submit"
                        className="btn btn-medium shadow-small btn-primary"
                        type="submit"
                    />
                </form>
            </div>
        </>
        )
    }
}
