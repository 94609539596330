import React, { Fragment, useEffect, useState } from 'react';
import '../DesignBuilder.css';
import Editor from '@monaco-editor/react';
import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';
import CopyButton from 'components/Button/CopyButton';

interface propsType {
    showCodeOverlay: any;
    createdDesign: designDetailsFormInterface[];
}

export default function DesignBuilderOverlay(props: propsType) {
    const [codeString, setcodeString] = useState<string>(
        '<!--Create Page to get Code-->',
    );

    useEffect(() => {
        async function combineCodeString() {
            let tempString: string = '';
            for (let i = 0; i < props.createdDesign.length; i++) {
                tempString += props?.createdDesign[i].html;
            }
            await setcodeString(tempString);
        }
        combineCodeString();
    }, [props.createdDesign]);

    function handleCloseOverlay() {
        props.showCodeOverlay(false);
    }

    return (
        <>
            <div className="dbo-container-wrapper">
                <div className="dbo-container">
                    <div className="dbo-code-box">
                        <div className="dbo-code-header">
                            <div className="dbo-code-left">Code</div>
                            <div className="dbo-code-right">
                                <div
                                    className="dbo-code-cross tw-cursor-pointer"
                                    onClick={handleCloseOverlay}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="m11.25 4.75l-6.5 6.5m0-6.5l6.5 6.5"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="dbo-code-block">
                            <div className="dbo-code-area">
                                <div className="tw-relative tw-z-50">
                                    <CopyButton input={codeString} />
                                </div>
                                <Editor
                                    height={'600px'}
                                    width={`100%`}
                                    language={'html'}
                                    theme={'vs-dark'}
                                    defaultValue={codeString}
                                    // onChange={this.props.onChange}
                                    options={{
                                        automaticLayout: true,
                                        formatOnPaste: true,
                                        readOnly: true,
                                    }}
                                    value={codeString}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
