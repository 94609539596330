import React, { Component } from 'react';

export default class LandingPageInspirationCard extends Component {
    componentDidMount() {}

    render() {
        return (
            <div>
                <div className="card design-inspiration-layout">
                    <a
                        href={
                            '/inspiration-item-details/' +
                            this.props.inspiration.Id
                        }
                    >
                        <div className="card-body p-0 tw-overflow-hidden tw-h-[400px]">
                            <img
                                src={
                                    this.props.inspiration.desktopViewImage.includes(
                                        'DesignInspiration',
                                    )
                                        ? 'https://ik.imagekit.io/c8xfvkbcd/tr:q-75,w-300,h-400,fo-top/' +
                                          this.props.inspiration
                                              .desktopViewImage
                                        : 'https://ik.imagekit.io/c8xfvkbcd/DesignInspiration/' +
                                          this.props.inspiration
                                              .desktopViewImage
                                }
                                className="tansformed-img tranformed-img-mobile"
                                alt={this.props.inspiration.Productname}
                            />
                        </div>
                    </a>
                </div>
                <div
                    className="text-center"
                    style={{ width: '300px', paddingTop: '20px' }}
                >
                    <h6 className="tw-m-0">
                        {this.props.inspiration.Productname}
                    </h6>
                    <p className="tw-text-sm">
                        Published on{' '}
                        {new Date(
                            this.props.inspiration.createdAt,
                        ).toLocaleDateString('default', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                        })}
                    </p>
                </div>
            </div>
        );
    }
}
