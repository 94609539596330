import React, { Component } from 'react'

export default class AdvertiseComponentsProduct extends Component {
  render() {
    return (
        <div class="card mt-5 advertise-web-component-product">
            <div class="card-body">
                <div class="display-flex mb-block">
                    <div>
                        <h6>{this.props.heading}</h6>
                        <p>{this.props.description}</p>
                    </div>
                    <div>
                        <a href={this.props.href} class="sb-cta sb-cta-modifier-red margin-top_20 sb-marginsides-5 w-button">{this.props.ctaText}</a>
                    </div>
                </div>
                
            </div>
        </div>
    )
  }
}
