import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import designDetailsFormImpl from "data/design/designDetailsForm/designDetailsFormImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.specificDesignFullWindow;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl())
        return pageSEO;
    }
    
    async getDesignsbyId(Id) {
        let params = { designId: Id }
        let result = await axiosCall(ApiRouteMapping.getDesignbyId.apiPath, "GET", params);
        const design = new designDetailsFormImpl();
        design.initfromDataObj(result.data.design);
        return design;
    }
}