import ApiRouteMapping from "utils/AllApiRouteMapping";
import axiosCall from "./axios";

class stripe {
    
    async onSubmit() {
        let result = await axiosCall(
            ApiRouteMapping.getCheckoutUrl.apiPath,
            'GET',
        );
        return result.data.url;

    }

}

export default stripe;