const nextInstallationData = [
    {
        path: 'install-nextjs',
        title: 'Install Next js',
        description: 'How to install dependencies and structure your app.',
        steps: [
            {
                title: 'Install Dependencies',
                code: `npm i next-codeui`,
            },
            {
                title: 'Install Additional Dependencies',
                code: `npm i framer-motion clsx tailwind-merge @tabler/icons-react`,
            },

            {
                title: 'Utlis Code',
                code: `import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
`,
            },
            {
                title: 'tailwindconfig.ts',
                code: `const defaultTheme = require("tailwindcss/defaultTheme");
const colors = require("tailwindcss/colors");
const {
  default: flattenColorPalette,
} = require("tailwindcss/lib/util/flattenColorPalette");

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{ts,tsx}"],
  darkMode: "class",
  theme: {
    // rest of the code
  },
  plugins: [
    // rest of the code
    addVariablesForColors,
  ],
};

// This plugin adds each Tailwind color as a global CSS variable, e.g. var(--gray-200).
function addVariablesForColors({ addBase, theme }: any) {
  let allColors = flattenColorPalette(theme("colors"));
  let newVars = Object.fromEntries(
    Object.entries(allColors).map(([key, val]) => [\`--key\`, val])
  );

  addBase({
    ":root": newVars,
  });
}
`,
            },
        ],
    },
    {
        path: 'install-tailwind-css',
        title: 'Install Tailwind CSS',

        description: 'Install Tailwind CSS with Next.js',
        steps: [
            {
                title: 'Create your project',
                code: `npx create-next-app@latest my-project --typescript --eslint
cd my-project`,
            },

            {
                title: 'Install Tailwind CSS',
                code: `npm install -D tailwindcss postcss autoprefixer
npx tailwindcss init -p`,
            },
            {
                title: 'Configure your template paths',
                description: 'tailwind.config.ts',
                code: `/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./app/**/*.{js,ts,jsx,tsx,mdx}",
    "./pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./components/**/*.{js,ts,jsx,tsx,mdx}",

    // Or if using "src" directory:
    "./src/**/*.{js,ts,jsx,tsx,mdx}",
    ],
  theme: {
  extend: {},
  },
  plugins: [],
};`,
            },
            {
                title: 'Add the Tailwind directives to your CSS',
                description: 'globals.css',
                code: `@tailwind base;
@tailwind components;
@tailwind utilities;`,
            },
            {
                title: 'Start your build process',
                code: `npm run dev`,
            },
            {
                title: 'Start using Tailwind',
                description: 'index.tsx',
                code: `export default function Home() {
return <h1 className="text-3xl font-bold underline">Hello world!</h1>;
}`,
            },
        ],
    },
    {
        path: 'add-utilities',
        title: 'Add Utilities',
        description: 'Commonly used utilities for using Aceternity UI',
        steps: [
            {
                title: 'Install Dependencies',

                code: `npm i framer-motion clsx tailwind-merge`,
            },

            {
                title: 'Add util file',
                description: 'utils/cn.ts',
                code: `import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
`,
            },
            {
                title: 'Base Tailwind Config File',
                description: 'tailwind.config.ts',
                code: `const {
  default: flattenColorPalette,
} = require("tailwindcss/lib/util/flattenColorPalette");

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",

    // Or if using src directory:
    "./src/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  darkMode: "class",
  theme: {
    extend: {},
  },
  plugins: [addVariablesForColors],
};

// This plugin adds each Tailwind color as a global CSS variable, e.g. var(--gray-200).
function addVariablesForColors({ addBase, theme }: any) {
  let allColors = flattenColorPalette(theme("colors"));
  let newVars = Object.fromEntries(
    Object.entries(allColors).map(([key, val]) => ['--{key}', val])
  );

  addBase({
    ":root": newVars,
  });
}
`,
            },
        ],
    },
];

export default nextInstallationData;
