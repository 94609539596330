import designInspirationDetailInterface from 'data/designInspiration/designInspirationDetailForm/designInspirationDetailInterface'

export default class designInspirationDetailImpl implements designInspirationDetailInterface {
    Id: string;
    Productname: string;
    description: string;
    desktopViewImage: string;
    mobileViewImage: string;
    websitelink: string;
    category: string;
    createdAt: string;
    updatedAt: string;

    initFromDataObject(data) {
        this.Id = data.Id;
        this.Productname = data.Productname;
        this.description = data.description;
        this.desktopViewImage = data.desktopViewImage;
        this.mobileViewImage = data.mobileViewImage;
        this.websitelink = data.websitelink;
        this.category = data.category;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }
}