import PrimaryLink from 'components/Button/PrimaryLink';
import React, { useEffect, useState } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { IoClose } from 'react-icons/io5';
import { cn } from 'utils/cn';
import jwt_decode from 'jwt-decode';
import PrimaryButton from 'components/Button/PrimaryButton';

const navigation = [
    {
        title: 'Inspirations',
        link: '/landing-page-inspiration',
    },
    {
        title: 'Framer Components',
        link: '/nextjs-component',
    },
    {
        title: 'Web Components',
        link: '/designs',
    },
    {
        title: 'Templates',
        link: '/templates',
    },
];
export default function Navbar() {
    const [isOpen, setIsopen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({ id: '', image: '' });

    useEffect(() => {
        if (localStorage.getItem('token')) setIsLoggedIn(true);

        if (isLoggedIn) {
            var decodedHeader: any = jwt_decode(localStorage.getItem('token'));
            setUser({
                id: decodedHeader.id,
                image: decodedHeader.image,
            });
        }
    }, [isLoggedIn]);

    const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        localStorage.removeItem('token');
        window.location.reload();
    };

    return (
        <div
            style={{ boxShadow: '0px 4px 100px 0px #0000001A' }}
            className="tw-sticky tw-top-0 tw-w-full tw-bg-white tw-h-20 tw-z-50 "
        >
            <div className="header tw-container">
                <div className="tw-p-0 tw-m-0 tw-flex tw-justify-start w-full">
                    <a href="/">
                        <img
                            src="https://ik.imagekit.io/codeui/CODE%20UI/codeuilogo?updatedAt=1715879107419"
                            alt=""
                        />
                    </a>
                </div>
                <nav className="lg:tw-flex tw-hidden tw-items-center tw-justify-center tw-flex-grow">
                    <ul className="tw-flex tw-items-center tw-justify-between tw-gap-[15px] tw-flex-wrap">
                        {navigation.map((item, key) => (
                            <li key={key}>
                                <PrimaryLink
                                    className="tw-text-base tw-font-normal"
                                    variant="link"
                                    href={item.link}
                                >
                                    {item.title}
                                </PrimaryLink>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="lg:tw-flex  tw-items-center tw-justify-end tw-gap-5 tw-hidden">
                    {isLoggedIn ? (
                        <>
                            <PrimaryLink
                                variant="link"
                                href={'/profile/' + user.id}
                                className="profile-img p-0 tw-aspect-square"
                            >
                                <img src={user.image} alt={'User Profile'} />
                            </PrimaryLink>
                            <PrimaryButton
                                onClick={handleLogout}
                                variant="outline"
                                className="tw-text-base"
                            >
                                Logout
                            </PrimaryButton>
                        </>
                    ) : (
                        <PrimaryLink
                            href="/login"
                            variant="outline"
                            className="tw-text-base"
                        >
                            Login
                        </PrimaryLink>
                    )}
                    <PrimaryLink className="tw-text-base">
                        Starter Templates
                    </PrimaryLink>
                </div>
                <div className="hamburger-icon">
                    <button onClick={() => setIsopen(!isOpen)}>
                        {!isOpen ? (
                            <RxHamburgerMenu className="tw-h-8 tw-w-8" />
                        ) : (
                            <IoClose className="tw-h-8 tw-w-8" />
                        )}
                    </button>
                </div>
            </div>
            {/* dropdown menu */}
            <div
                className={cn(
                    'tw-absolute tw-top-[70px] tw-flex tw-flex-col tw-p-4 tw-gap-4 tw-items-center tw-justify-center tw-right-0 tw-left-0 tw-w-full tw-bg-white tw-shadow-md tw-border-t tw-transition-all tw-duration-500 tw-ease-in-out tw-z-10 lg:tw-hidden',
                    {
                        '-tw-translate-y-[150%]': !isOpen,
                        'tw-translate-y-0': isOpen,
                    },
                )}
            >
                <nav className="tw-flex">
                    <ul className="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-2.5">
                        {navigation.map((item, key) => (
                            <li key={key}>
                                <PrimaryLink
                                    className="tw-text-base"
                                    variant="link"
                                    href={item.link}
                                >
                                    {item.title}
                                </PrimaryLink>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="tw-flex tw-flex-col tw-gap-2.5">
                    {isLoggedIn ? (
                        <div className="tw-flex tw-items-center tw-gap-2">
                            <PrimaryLink
                                variant="link"
                                href={'/profile/' + user.id}
                                className="profile-img p-0 tw-w-full"
                            >
                                <img src={user.image} alt={'User Profile'} />
                            </PrimaryLink>
                            <PrimaryButton
                                onClick={handleLogout}
                                variant="outline"
                                className="tw-text-base tw-w-full"
                            >
                                Logout
                            </PrimaryButton>
                        </div>
                    ) : (
                        <PrimaryLink
                            href="/login"
                            variant="outline"
                            className="tw-text-base"
                        >
                            Login
                        </PrimaryLink>
                    )}
                    <PrimaryLink className="tw-text-base">
                        Starter Templates
                    </PrimaryLink>
                </div>
            </div>
        </div>
    );
}
