import React, { Component } from 'react';

import Headers from '../../components/SEO/Headers';

import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';

import NextjsDesignCard from 'components/Card/NextjsDesignCard';
import H1Hide from 'components/SEO/H1Hide';
import SidebarItem from 'components/SidebarItem/SidebarItem';
import nextInstallationData from 'utils/nextInstallationDataJson';
import functions from 'pages/NextJsComponent/functions';
import Navbar from 'components/Navbars/Navbar';

interface DesignList {
    Id: string;
    title: string;
    description: string;
    imageURL: string;
    steps: string;
    views: number;
    cliSteps: string;
    dependencies: string;
    devDependencies: string;
}

export default class NextjsComponent extends Component<
    {},
    {
        pageSEO: pageSEOInterface;
        designs: DesignList[];
        isLoading: boolean;
        nextjsInstallation: any;
    }
> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);

        this.state = {
            pageSEO: {} as pageSEOInterface,
            designs: [] as any,
            isLoading: true as boolean,
            nextjsInstallation: {} as any,
        };
    }

    componentWillMount() {
        this.init();
    }

    async init() {
        const componentHandler = new functions();
        const SEO = componentHandler.addSEO();
        this.setState({ pageSEO: SEO });
        this.setComponent();
    }
    async setComponent() {
        const componentHandler = new functions();
        const componentResult = await componentHandler.getNextjsComponent();

        this.setState({
            designs: componentResult,
        });

    }
    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="nextjs-Container tw-container ">
                    <div className="nextjs-navContainer webkit-scroll ">
                        <div className="nextjsnav">
                            <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                                <span className="black-text font-weight-bold">
                                    Follow For Updates
                                </span>
                                <div className="d-flex flex-col">
                                    <SidebarItem text="Twitter @uday" />
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                                <span className="black-text font-weight-bold filter-heading">
                                    Installation
                                </span>
                                {nextInstallationData.map((items, i) => (
                                    <SidebarItem
                                        key={i}
                                        text={items.title}
                                        link={`/docs/${items.path}`}
                                    />
                                ))}
                            </div>
                            <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                                <a href="/nextjs-component">
                                    <span className="black-text font-weight-bold filter-heading">
                                        All Components
                                    </span>
                                </a>
                                {this.state.designs.map((items, i) => (
                                    <SidebarItem
                                        key={i}
                                        text={items.title}
                                        link={`/nextjs-component/${items.Id}`}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                     {/* Design Component */}
                    <div className="nextjs-designsContainer webkit-scroll">
                        <div className="nextjs-designs">
                            {this.state.designs.map((items, i) => (
                                <NextjsDesignCard
                                    key={i}
                                    path={items.Id}
                                    title={items.title}
                                    description={items.description}
                                    image={items.imageURL}
                                    views={items.views}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
