import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";

export default class functions {
    async submitFeeback(dataJson) {

        const payload = {
            data: dataJson,
        }
        let result = await axiosCall(ApiRouteMapping.addFeedback.apiPath, "POST", null, null, payload);
        return result;   
    }
}