import React, { Component } from 'react'
import { Editor } from "@tinymce/tinymce-react";

import env from '../config'


export default class TinyMCEEditor extends Component<any,any> {
  render() {
    return (
        <Editor
            apiKey={env.tinyMCE.apiKey}
            // onInit={(evt, editor) => editorRef.current = editor}
            initialValue="<p>This is the initial content of the editor.</p>"
            onEditorChange = {this.props.onEditorChange}
            textareaName={this.props.textareaName}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'codesample'
                ],
                codesample_global_prismjs: true,
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | image | code | codesample | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                codesample_languages: [
                    { text: 'HTML/XML', value: 'markup' },
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'CSS', value: 'css' },
                    { text: 'PHP', value: 'php' },
                    { text: 'Ruby', value: 'ruby' },
                    { text: 'Python', value: 'python' },
                    { text: 'Java', value: 'java' },
                    { text: 'C', value: 'c' },
                    { text: 'C#', value: 'csharp' },
                    { text: 'C++', value: 'cpp' }
                ]
            }}
        />
    )
  }
}
