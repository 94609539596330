import stripe from 'lib/stripe';
import React, { Component } from 'react';
import PricingCard from './PricingCard';

const SubscriptionBenefits = [
    'All components',
    '5 component designs',
    '1 Landing Page Roast',
    'Access to all the future updates',
];

export default class PricingTable extends Component<any, any> {

    componentDidMount(): void {
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit() {
        if (localStorage.getItem('token')) {
            const stripeInstance = new stripe();
            const url = await stripeInstance.onSubmit();
            window.location.href = url;
        } else {
            window.location.href = '/login';
        }
        
    }

    render() {
        return (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-[140px] tw-px-[15px] ">
                <p className="tw-text-grey/80 tw-text-base tw-font-semibold tw-mb-3">
                    Pricing
                </p>
                <h2 className="tw-text-black tw-text-center tw-text-4xl md:tw-text-5xl tw-m-0 tw-p-0 tw-tracking-[-1.98%]">
                    Deal Grabber for Developers
                </h2>

                <div className="tw-flex tw-items-center tw-justify-center tw-px-6 tw-gap-5">
                    {/* cards */}
                    <PricingCard
                        title="Lifetime Plan"
                        price="$9"
                        description="Perfect for Indiehackers and early startup founders"
                        features={SubscriptionBenefits}
                        buttonText="Buy now"
                        buttonLink="javascript:void(0);"
                        buttonOnClick={this.onSubmit}
                    />
                </div>

                <div className='tw-text-[#2F3033] tw-text-base tw-flex tw-items-center tw-gap-2.5 tw-mt-7'> <div className='tw-bg-[#398524] tw-h-2 tw-w-2 tw-rounded-full' ></div> New Components added Every 2 Weeks</div>
            </div>
        );
    }
}
