import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

interface propsType{
    design: minDesignFormInterface,
    index: number,
    isComponent: boolean,
}

export default function DesignBuilderCard(props: propsType) {
  return (
    <>
        <div className="dbc-container shadow-small">
            <Draggable draggableId={props.design.Id} index={props.index}>
                {
                    (provided) => (
                        <div className="CardImg-container " ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <img src={"https://ik.imagekit.io/c8xfvkbcd/tr:q-75/" + props.design.imageLink}
                                className='design-Image'
                                alt={props.design.name }
                            />
                        </div>
                    )
                }
            </Draggable>
            {
                props.isComponent ?
                    <div className="dbc-container-name padding-20" style={{background: 'rgb(var(--sail-color-blue-50-rgb))'}}><p className="margin-0 margin-left-10">{props.design.name}</p></div>
                :
                <></>
            }
            
        </div>
    </>
  )
}
