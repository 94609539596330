import React, { Component } from 'react';

export default class InputRadio extends Component<any, any> {
    render() {
        return (
            <>
                <div>
                    <div
                        className="flex"
                        style={{
                            gap: 10,
                            alignItems: 'center',
                        }}
                    >
                        <input
                            className=""
                            type={this.props.type}
                            name={this.props.name}
                            id={this.props.id}
                            value={this.props.value}
                            defaultChecked={this.props?.defaultChecked}
                            onChange={this.props.onChange}
                        />
                        <label
                            style={{
                                marginBottom: 0,
                            }}
                            htmlFor={this.props.id}
                        >
                            {this.props.label}
                        </label>
                    </div>
                </div>
            </>
        );
    }
}
