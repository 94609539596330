import blogDetailFormInterface from "./blogDetailFormInterface";

export default class blogDetailFormImpl implements blogDetailFormInterface {
    Id: string;
    title: string;
    image: string;
    content: string;
    postedBy: string;
    tags: string;
    views: string;
    createdAt: string;
    updatedAt: string;

    initFromDataObject(data) {
        this.Id = data.Id
        this.title = data.title
        this.image = data.image
        this.content = data.content
        this.postedBy = data.postedBy
        this.tags = data.tags
        this.views = data.views
        this.createdAt = data.createdAt
        this.updatedAt = data.updatedAt
    }
}