import axiosCall from 'lib/axios';

import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface';
import designCategoriesFormImpl from 'data/design/designCategoriesForm/designCategoriesFormImpl';

import designFormImpl from 'data/design/designForm/designFormImpl';
import pageSEOImpl from 'data/SEO/pageSEO/pageSEOImpl';
import minDesignFormImpl from 'data/design/minDesignForm/minDesignFormImpl';

import PageSEOData from 'utils/AllPageSEOData';
import ApiRouteMapping from 'utils/AllApiRouteMapping';
import helperFunctionsImpl from 'utils/helperFunctionsUtils/helperFunctionsImpl';

class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Design;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getDesigns(filters = undefined) {
        let result = await axiosCall(
            ApiRouteMapping.getDesigns.apiPath,
            'GET',
            filters,
        );
        const designs = await this.convertAPIresultToFormModal(result);
        return designs;
    }

    async getDesignsWithCategories(categoryId, type) {
        let result = await axiosCall(
            ApiRouteMapping.getDesignsWithCategories.apiPath +
                categoryId +
                `/${type}`,
            'GET',
        );
        const designs = await this.convertAPIresultToFormModal(result);
        return designs;
    }

    async getDesignsForBuilder(filters = undefined) {
        let result = await axiosCall(
            ApiRouteMapping.getDesigns.apiPath,
            'GET',
            filters,
        );
        // const designs = await this.convertAPIresultToFormModal(result);
        return result.data.designs.designs;
    }

    async getCategories() {
        let result = await axiosCall(
            ApiRouteMapping.getAllCategories.apiPath,
            'GET',
        );
        const categories = [];

        for (let i = 0; i < result.data.categories.length; i++) {
            const designCategoriesForm = new designCategoriesFormImpl();
            designCategoriesForm.initDataFromObject(result.data.categories[i]);
            categories.push(designCategoriesForm);
        }

        return categories;
    }

    async getDesignType() {
        let result = await axiosCall(
            ApiRouteMapping.getAllDesignTypeWithCategories.apiPath,
            'GET',
        );
        if (result.data.success) {
            return result.data.type;
        }
    }
    async getNextjsComponent() {
        let result = await axiosCall(ApiRouteMapping.nextjsGetDesigns.apiPath, "GET");
        return result.data.designs.designs;
    }
    
    convertAPIresultToFormModal(data) {
        const designs = new designFormImpl<minDesignFormInterface>();
        designs.initFromDataObject(
            data.data.designs.designs,
            minDesignFormImpl,
        );
        return designs;
    }
}

export default functions;
