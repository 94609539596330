import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {

    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.LandingPageInspiration;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getDesignInspirations(designInspiration) {
        let params = {
            start: designInspiration.getfetchFrom(),
            count: designInspiration.getcount(),
            itemsFetched: designInspiration.getitemsFetched()
        }
        let result = await axiosCall(ApiRouteMapping.getDesignInspirations.apiPath, "GET", params);
        designInspiration.initFromDataObject(result.data.designsInspiration.designInspirations);

        if (designInspiration.getdesignInspirations().length < designInspiration.getcount()) {
            designInspiration.sethasmore(false);
        }
        designInspiration.setitemsFetched(designInspiration.getitemsFetched() + designInspiration.getdesignInspirations().length);

        return designInspiration;
    }
}