import React, { Component } from 'react';
export default class SidebarItem extends Component<
    any,
    {
        text: string;
        link: string;
    }
> {
    render(): React.ReactNode {
        return (
            <React.Fragment>
                    <a className="sidebar-item" href={this.props.link}>
                        {this.props.text}
                    </a>
            </React.Fragment>
        );
    }
}
