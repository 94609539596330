import * as React from 'react';
import { cn } from 'utils/cn';

type PrimaryButtonProps = {
    variant?: 'outline';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export default function PrimaryButton({
    className,
    variant,
    ...props
}: PrimaryButtonProps) {
    let defaultStyle =
        'tw-text-white tw-bg-blue hover:tw-bg-transparent hover:tw-text-blue ';
    if (variant) {
        if (variant === 'outline')
            defaultStyle =
                'tw-text-blue tw-bg-transparent hover:tw-bg-blue hover:tw-text-white ';
    }
    return (
        <button
            {...props}
            className={cn(
                'tw-text-sm tw-flex tw-items-center tw-border tw-border-solid tw-border-blue tw-justify-center tw-text-center tw-rounded-full tw-py-2.5 tw-px-5 tw-transition-all tw-duration-200 tw-ease-in-out',
                defaultStyle,
                className,
            )}
        />
    );
}
