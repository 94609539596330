import React, { Component, ReactNode } from 'react'

interface GalleryLayoutProps {
  children: ReactNode;
}

export default class GallaryLayout extends Component<GalleryLayoutProps> {
  render() {
    return (
        <div className="grid-overall-container">
            <div>
                {
                    this.props.children
                }
            </div>
        </div>
    )
  }
}
