import React, { Component } from 'react'

export default class HeroSection extends Component<any, any> {    
    render() {
        return (
            <>
            <div className="sb-hero flex-vertical-center-center padding-y-60 wf-section">
                <div className="global-container justify-content-center">
                    <div className="columns-equal-height w-row">
                        <div className="flex-vertical-center w-col w-col-6 w-col-stack">
                            <div className="color-slategray-black">
                                <h1 className="text_64 no-margin">
                                    <span className="text_allcaps">Generate Your Stunning Twitter Bio</span>
                                </h1>
                                <div className="margin-y-40 margin-right_20 text_20">Let AI generate a twitter bio for you</div>
                            </div>
                        </div>
                        <div className="flex-vertical-center-center w-col w-col-6 w-col-stack hero-section-image">
                            <img src="https://ik.imagekit.io/c8xfvkbcd/Landing_Page_Images/hero-image.png?updatedAt=1687578867987" alt={"Buildfast Components Grid"}/>
                        </div>
                    </div>
                </div>                
            </div>  
            </>
        )
    }
}
