import React, {Component} from 'react';
import {Helmet} from "react-helmet";

import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';

export default class Headers extends Component<{SEO: pageSEOInterface}> {
  render() {
    return (
      <Helmet>
          <title>{this.props.SEO.title}</title>
          <meta name="description" content={this.props.SEO.description} />
          <meta itemProp="name" content={this.props.SEO.title} />
          <meta itemProp="description" content={this.props.SEO.description} />
          <meta itemProp="image" content={this.props.SEO.image} />
          <meta name="keywords" content={this.props.SEO.keywords} />

          <meta property="og:url" content={this.props.SEO.url} />
          <meta property="og:type" content={this.props.SEO.pagetype} />
          <meta property="og:title" content={this.props.SEO.title} />
          <meta property="og:description" content={this.props.SEO.description} />
          <meta property="og:image" content={this.props.SEO.image} />


          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={this.props.SEO.title} />
          <meta name="twitter:description" content={this.props.SEO.description} />
          <meta name="twitter:image" content={this.props.SEO.image} />
          
      </Helmet>
    )
  }
}
