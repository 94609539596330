import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LandingPageInspirationCard from 'components/Card/LandingPageInspirationCard';
import designInspirationInterface from 'data/designInspiration/designInpirationForm/designInspirationInterface';

export default class LandingPageInspirationSection extends Component<
    {
        designInspiration: designInspirationInterface;
        getDesignInspirations: any;
    },
    {}
> {

    render() {
        return (
            <React.Fragment>
                {this.props.designInspiration.designInspirations.map(
                    (designInspiration, index) => {
                        return (
                            <div key={index} className="grid-item">
                                <LandingPageInspirationCard
                                    inspiration={designInspiration}
                                />
                            </div>
                        );
                    },
                )}
                <InfiniteScroll
                    dataLength={
                        this.props.designInspiration.designInspirations.length
                    }
                    next={() =>
                        this.props.getDesignInspirations(
                            this.props.designInspiration,
                        )
                    }
                    hasMore={this.props.designInspiration.hasmore}
                    loader={<h4> Loading... </h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    children={''}
                ></InfiniteScroll>
            </React.Fragment>
        );
    }
}
