import CopyButton from 'components/Button/CopyButton';
import CodeBlock from 'components/CodeBlock/CodeBlock';
import HighlightCodeTitle from 'components/HighlightCode/HighlightCodeTitle';
import React, { Component } from 'react';

export default class CodeSteps extends Component<any, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="steps">
                    <HighlightCodeTitle title={this.props.title} />
                    {this.props.desc && (
                        <p className="step-desc">{this.props.desc}</p>
                    )}
                    <div className="relative" style={{ marginLeft: '24px' }}>
                        <CodeBlock
                            language="javascript"
                            code={this.props.code}
                        />
                        <CopyButton input={this.props.code} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
