import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import React, { Component } from 'react';
import HeroSection from './components/HeroSection';

interface state {
    bio: string,
    vibe: string,
    pageSEO: pageSEOInterface
}
export default class TwitterBioGenerator extends Component<any, state> {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
            bio: '' as string,
            vibe: '' as string,
        }
    }

    componentDidMount(): void {
        this.init();
    }

    init() {

    }

    render() {
        return (
            <>
                <Navbar />
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <HeroSection 
                />
            </>
        )
    }
}
