import React, { Component } from 'react'

import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

export default class SmallFrame extends Component<{ design: designDetailsFormInterface, height: string, className: string }, { designDoc: string }> {
    constructor(props) {
        super(props);

        this.populateDesignDocwithHTML = this.populateDesignDocwithHTML.bind(this);
        this.state= {
            designDoc: '' as string
        }
    }

    componentDidUpdate(prevProps: Readonly<{ design: designDetailsFormInterface; }>, prevState: Readonly<{ designDoc: string; }>, snapshot?: any): void {
        if (prevProps.design !== this.props.design) {
            this.populateDesignDocwithHTML();
        }
    }

    populateDesignDocwithHTML() {
        if (!jsonUtilsImpl.isEmpty(this.props.design)) {
            if (!stringUtilsImpl.isUndefinedEmptyorNull(this.props.design.html) || !stringUtilsImpl.isUndefinedEmptyorNull(this.props.design.css) || !stringUtilsImpl.isUndefinedEmptyorNull(this.props.design.css)) {
                let htmlCode = `
                                    <html>
                                        <body>${this.props.design.html}</body>
                                        <style>${this.props.design.css}</style>
                                        <script>${this.props.design.javascript}</script>
                                    </html>
                                `

                this.setState({
                    designDoc: htmlCode
                })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.design.html) || !stringUtilsImpl.isUndefinedEmptyorNull(this.props.design.css) || !stringUtilsImpl.isUndefinedEmptyorNull(this.props.design.css) ?
                        <iframe 
                            className={"small-iframe " + this.props.className}
                            title="output"
                            sandbox="allow-scripts"
                            width="100%"
                            height= {this.props.height}
                            srcDoc={this.state.designDoc} 
                        />
                        :
                        <></>
                
                }
            </React.Fragment>
        )
    }
}
