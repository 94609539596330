import React, { Component } from 'react';
import Select from 'react-select';

import SectionWiseInput from 'components/Form/SectionWiseInput';
import SmallFrame from 'components/Frame/SmallFrame';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import functions from './functions';
// import UploadImageInput from 'components/InputGroup/UploadImageInput';
import Button from 'components/Button/Button';
import EditorComponent from 'components/Editor/EditorComponent';
import Input from 'components/InputGroup/Input';
import InputRadio from 'components/InputGroup/Radio/InputRadio';
import Navbar from 'components/Navbars/Navbar';
import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';
import ReactSelectInterface from 'data/ReactSelect/ReactSelectInterface';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

export default class SubmitDesign extends Component<
    any,
    {
        designVariant: string;
        pageSEO: pageSEOInterface;
        design: designDetailsFormInterface;
        categories: ReactSelectInterface[];
        types: ReactSelectInterface[];
        iscategoryOther: boolean;
        istypeOther: boolean;
    }
> {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.changeDesignVariant = this.changeDesignVariant.bind(this);

        this.state = {
            designVariant: 'html-css-js',
            pageSEO: {} as pageSEOInterface,
            design: {} as designDetailsFormInterface,
            categories: [] as ReactSelectInterface[],
            types: [] as ReactSelectInterface[],
            iscategoryOther: false as boolean,
            istypeOther: false as boolean,
        };
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {
        const SubmitDesignHandler = new functions();
        const designHandler = new functions();

        const SEO = SubmitDesignHandler.addSEO();
        this.setState({ pageSEO: SEO });

        const categoriesResult = await SubmitDesignHandler.getCategories();
        const categoriesResultArray = categoriesResult.map((category) => {
            return {
                label: category.name,
                value: category.Id,
            };
        });
        if (categoriesResult.length > 0) {
            this.setState({
                categories: [
                    ...categoriesResultArray,
                    { label: 'Other', value: 'Other' },
                ],
            });
        }

        const designTypeResult = await designHandler.getDesignType();
        const designTypeResultArray = designTypeResult.map(
            (designTypeValue) => {
                return {
                    label: designTypeValue.type,
                    value: designTypeValue.type,
                };
            },
        );
        this.setState({
            types: [
                ...designTypeResultArray,
                { label: 'Other', value: 'Other' },
            ],
        });
    }
    showInputForOther(editorName, value) {
        let stateJson = {};

        if (editorName === 'category') {
            stateJson['iscategoryOther'] = value;
        }

        if (editorName === 'type') {
            stateJson['istypeOther'] = value;
        }

        this.setState(stateJson);
    }
    changeDesignVariant(variantType: string) {
        this.setState({
            designVariant: variantType,
        });
    }

    onRadioChange(e, editorName) {
        this.setState((prevState) => ({
            design: {
                ...prevState.design,
                isTailwind: editorName === 'isTailwind' ? true : false,
                isNextjs: editorName === 'isNextjs' ? true : false,
            },
        }));
    }

    onChangeHandler(e, editorName) {
        let name = '';
        let value = '';

        if (editorName === 'category' || editorName === 'type') {
            name = editorName;
            value = e.value;

            if (value === 'Other') {
                // Showing Input Box
                this.showInputForOther(name, true);
            } else {
                // Hiiding Input Box
                this.showInputForOther(name, false);
            }
        } else if (!stringUtilsImpl.isUndefinedEmptyorNull(editorName)) {
            name = editorName;
            value = e;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        this.setState((prevState) => ({
            design: {
                ...prevState.design,
                [name]: value,
            },
        }));
    }

    async onSubmit(e) {
        e.preventDefault();
        const AddPostHandler = new functions();
        await AddPostHandler.addPost(this.state.design);
    }

    render() {
        return (
            <React.Fragment>
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="desk-container">
                    <section>
                        <p className="margin-0">Submit Design</p>
                        <hr />
                        <div className="margin-bottom-20">
                            <SmallFrame
                                className=""
                                design={this.state.design}
                                height="300px"
                            />
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <SectionWiseInput
                                sectionHeading="Designs"
                                sectionDescription="Do it"
                            >
                                <Input
                                    label="Design Name"
                                    name="name"
                                    onChange={this.onChangeHandler}
                                />
                                <Input
                                    label="Tagline"
                                    name="tagline"
                                    onChange={this.onChangeHandler}
                                />
                                <Input
                                    label="Image Link"
                                    name="imageLink"
                                    onChange={this.onChangeHandler}
                                />
                                <Input label="Category">
                                    <Select
                                        options={this.state.categories}
                                        name="category"
                                        onChange={(e) =>
                                            this.onChangeHandler(e, 'category')
                                        }
                                    />
                                </Input>
                                {this.state.iscategoryOther === true ? (
                                    <Input
                                        name="category"
                                        onChange={this.onChangeHandler}
                                    ></Input>
                                ) : (
                                    <></>
                                )}
                                <Input label="Type">
                                    <Select
                                        options={this.state.types}
                                        name="type"
                                        onChange={(e) =>
                                            this.onChangeHandler(e, 'type')
                                        }
                                    />
                                </Input>
                                {this.state.istypeOther === true ? (
                                    <Input
                                        name="type"
                                        onChange={this.onChangeHandler}
                                    ></Input>
                                ) : (
                                    <></>
                                )}
                                {/* radio for editor selection */}
                                <div
                                    className="flex"
                                    style={{
                                        gap: 18,
                                        alignItems: 'center',
                                    }}
                                >
                                    <InputRadio
                                        label="HTML CSS & JS"
                                        id="html-css-js"
                                        name="ediotorOption"
                                        type="radio"
                                        defaultValue={this.state.designVariant}
                                        defaultChecked={true}
                                        onChange={() =>
                                            this.changeDesignVariant(
                                                'html-css-js',
                                            )
                                        }
                                    />
                                    <InputRadio
                                        label="TAILWIND"
                                        id="tailwind"
                                        name="ediotorOption"
                                        type="radio"
                                        onChange={(e) => {
                                            this.onRadioChange(e, 'isTailwind');
                                            this.changeDesignVariant(
                                                'tailwind',
                                            );
                                        }}
                                        value="true"
                                        // onChange={() =>
                                        //     this.changeDesignVariant('tailwind')
                                        // }
                                    />
                                    <InputRadio
                                        label="NEXT JS"
                                        id="nextjs"
                                        name="ediotorOption"
                                        type="radio"
                                        onChange={(e) => {
                                            this.onRadioChange(e, 'isNextjs');
                                            this.changeDesignVariant('nextjs');
                                        }}
                                    />
                                </div>

                                {/* <Input label="Image Link">
                                    <UploadImageInput open={undefined} />
                                </Input> */}
                            </SectionWiseInput>

                            <hr />
                            <SectionWiseInput
                                sectionHeading="Code"
                                sectionDescription="Do it"
                            >
                                {this.state.designVariant === 'html-css-js' && (
                                    <EditorComponent
                                        label="HTML"
                                        language="html"
                                        onChange={(e) =>
                                            this.onChangeHandler(e, 'html')
                                        }
                                        height="200px"
                                        name="html"
                                    />
                                )}
                                {this.state.designVariant === 'html-css-js' && (
                                    <EditorComponent
                                        label="CSS"
                                        language="css"
                                        onChange={(e) =>
                                            this.onChangeHandler(e, 'css')
                                        }
                                        height="200px"
                                    />
                                )}
                                {this.state.designVariant === 'tailwind' && (
                                    <EditorComponent
                                        label="Tailwind"
                                        language="html"
                                        onChange={(e) =>
                                            this.onChangeHandler(e, 'html')
                                        }
                                        height="200px"
                                    />
                                )}
                                {(this.state.designVariant === 'html-css-js' ||
                                    this.state.designVariant ===
                                        'tailwind') && (
                                    <EditorComponent
                                        label="Javascript"
                                        language="javascript"
                                        onChange={(e) =>
                                            this.onChangeHandler(
                                                e,
                                                'javascript',
                                            )
                                        }
                                        height="200px"
                                    />
                                )}

                                {this.state.designVariant === 'nextjs' && (
                                    <EditorComponent
                                        label="Next JS"
                                        language="javascript"
                                        onChange={(e) =>
                                            this.onChangeHandler(
                                                e,
                                                'javascript',
                                            )
                                        }
                                        height="200px"
                                    />
                                )}
                            </SectionWiseInput>
                            <hr />
                            <SectionWiseInput
                                sectionHeading="Additional Information"
                                sectionDescription="Do it"
                            >
                                <Input
                                    label="Tags"
                                    name="tags"
                                    onChange={this.onChangeHandler}
                                />

                                <Button
                                    id={undefined}
                                    text="Submit Design"
                                    className="btn btn-large shadow-small btn-primary"
                                    type="submit"
                                />
                            </SectionWiseInput>
                        </form>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}
