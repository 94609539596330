import userFormInterface from "data/user/userForm/userFormInterface";


export default class userFormImpl implements userFormInterface {
    Id: string;
    firstname: string;
    lastname: string;
    email: string;
    image: string;
    loginFrom: string;
    googleId: string;
    subscriptionType: string;
    createdAt: string;
    updatedAt: string;

    public initFromDataObject(data) {
        this.Id = data.Id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.email = data.email;
        this.image = data.image;
        this.loginFrom = data.loginFrom;
        this.googleId = data.googleId;
        this.subscriptionType = data.subscriptionType;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }

    public setId(Id){
        this.Id = Id;
    }

    public getfullName() {
        return this.firstname + ' ' + this.lastname;
    }

    public getId() {
        return this.Id;
    }

    public getfirstname() {
        return this.firstname
    }

    public getlastname() {
        return this.lastname
    }

    public setemail(email) {
        this.email = email;
    }

    public getemail() {
        return this.email
    }

    public getimage() {
        return this.image
    }

    public setloginFrom(loginFrom) {
        this.loginFrom = loginFrom;
    }

    public getloginFrom() {
        return this.loginFrom
    }

    public getgoogleId() {
        return this.googleId;
    }

    public setsubscriptionType(subscriptionType) {
        this.subscriptionType = subscriptionType
    }

    public getsubscriptionType() {
        return this.subscriptionType;
    }

    public setcreatedAt(createdAt) {
        this.createdAt = createdAt;
    }

    public getcreatedAt() {
        return this.createdAt;
    }

    public setupdatedAt(updatedAt) {
        this.updatedAt = updatedAt;
    }

    public getupdatedAt() {
        return this.updatedAt;
    }
}

