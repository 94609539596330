import ConverterInterface from "./ConverterInterface";

export default class ConverterImpl implements ConverterInterface {
    Id: string;
    converterName: string;
    from: string;
    to: string;
    description: string;
    tags: string;
    createdAt: string;
    updatedAt: string;

    initFromDataObject(data) {
        this.Id = data.Id
        this.converterName = data.converterName
        this.from = data.from
        this.to = data.to
        this.description = data.description
        this.tags = data.tags
        this.createdAt = data.createdAt
        this.updatedAt = data.updatedAt
    }
}