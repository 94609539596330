import React, { Component } from 'react';

export default class HighlightCodeTitle extends Component<
    any,
    { title: String }
> {
    render() {
        return (
            <p className="step-title px-4">
                <span></span>
                {this.props.title}
            </p>
        );
    }
}
