import React, { Component } from 'react';

import GeneralFooter from 'components/Navbars/GeneralFooter';
import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import GetFeebackFloatingButton from 'features/feedbackFloatingButton/GetFeebackFloatingButton';
import functions from './functions';

export default class codeGPTLandingPage extends Component<any, { pageSEO: pageSEOInterface, }>  {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
        }
    }

    componentDidMount(): void {
        this.init();
    }

    init() {
        const landingPageHandler = new functions();

        const SEO = landingPageHandler.addSEO();
        this.setState({ pageSEO: SEO });
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                
                <div className="sb-hero flex-vertical-center-center padding-y-60 wf-section">
                    <div className="global-container sb-text-center">

                        <div className="">
                            <div className="">
                                <div className="color-slategray-black">
                                    <h1 className="text_64 no-margin">
                                        <span className="text_allcaps">CodeGPT: Let AI do design and coding for you.</span>
                                    </h1>
                                    <div className="margin-y-40 margin-right_20 text_20">Given Instructions and let AI do the work while you focus on other things.</div>
                                </div>
                                <div className="center-mobile _100--width">
                                    <div className="margin_bottom-10 sb-text-center">
                                        {/* <div className="text_20 sb-biofolio">Designed Web Components</div>
                        <a href="/designs" className="sb-cta sb-cta-modifier-blue margin-top_20 sb-marginsides-5 w-button">Get Started</a> */}
                                        <div id="CTA-Wrapper" className="sb-text-center">
                                            <div className="items-center">
                                                
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="flex-vertical-center-center padding-y-60 wf-section">
                    <div className="global-container sb-text-center">
                        <div className="margin-top_20">
                            <iframe width="100%" height="578" src="https://www.youtube.com/embed/TfN1H6rEtzY" title="Let AI code for you : CodeGPT" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>

                <div>
                    <GetFeebackFloatingButton />
                </div>
                <GeneralFooter />
            </React.Fragment>
        )
    }
}
