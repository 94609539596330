import React, { Component } from 'react'

import functions from './functions';

import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import Headers from 'components/SEO/Headers';
import blogFormInterface from 'data/blog/blogForm/blogFormInterface';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import BlogCard from 'components/Card/BlogCard';
import H1Hide from 'components/SEO/H1Hide';
import Navbar from 'components/Navbars/Navbar';

export default class Blogs extends Component<any, { pageSEO: pageSEOInterface, blogs: blogFormInterface }> {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
            blogs: {} as blogFormInterface
        }
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {    
        const blogsHandler = new functions();

        const SEO = blogsHandler.addSEO();
        this.setState({ pageSEO: SEO });

        const blogsResult = await blogsHandler.getBlogs();
        this.setState({
            blogs: blogsResult
        })
    }
    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />

                <Navbar />
                <section>
                    <div className='desk-container bg-white'>
                        <div className="buildfast-blog">
                            <div className="flex-vertical-center-center padding-y-60 wf-section">
                                <div className="global-container sb-text-center">
                                    <div className="margin-top_20">
                                        <h2 className="text_40 sb-biofolio no-margin">Blogs</h2>
                                        {/* <div className="margin-top_20 text_18">Our platfrom allows you to easily find and use code snippets of beautiful,
                                            high-quality web components in your projects.</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="buildfast-blog-content buildfast-blogs">

                                <div className="blog-grid-container buildfast-content-center">
                                    {
                                        !jsonUtilsImpl.isEmpty(this.state.blogs) ?

                                        this.state.blogs.blogs.map((blog) => {
                                            return(
                                                <>
                                                    <BlogCard
                                                        blog = {blog}
                                                    />
                                                </>
                                            )
                                        })
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </React.Fragment>
        )
    }
}
