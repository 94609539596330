import pageSEOImpl from 'data/SEO/pageSEO/pageSEOImpl';
import axiosCall from 'lib/axios';
import ApiRouteMapping from 'utils/AllApiRouteMapping';
import PageSEOData from 'utils/AllPageSEOData';
import designInspirationDetailImpl from 'data/designInspiration/designInspirationDetailForm/designInspirationDetailImpl';
import helperFunctionsImpl from 'utils/helperFunctionsUtils/helperFunctionsImpl';
export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Profile;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getDesignInpirationbyId(Id) {
        let params = { inspirationId: Id };
        let result = await axiosCall(
            ApiRouteMapping.getDesignInspirationbyId.apiPath,
            'GET',
            params,
        );

        let design = new designInspirationDetailImpl();
        design.initFromDataObject(
            result.data.specificInspiration.designInspirations[0],
        );
        return design;
    }
}
