import React, { useState, useEffect, useCallback } from 'react';
import Headers from '../../components/SEO/Headers';
import functions from './functions';
import CodeSteps from 'components/CodeSteps/CodeSteps';
import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import SidebarItem from 'components/SidebarItem/SidebarItem';
// import { Tab, TabList, TabPanel, Tabs } from 'components/Tabs/Tabs';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import nextInstallationData from 'utils/nextInstallationDataJson';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';
import HighlightCode from 'components/HighlightCode/HighlightCode';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/Tabs/Tab';

type Design = {
    Id: string;
    title: string;
    description: string;
    imageURL: string;
    hostedURL: string;
    steps: {
        typeScript: any;
        javascript: any;
    };
    cliSteps: any[];
    codeUsage: any[];
    creator: string;
    creatorProfile: string;
    dependencies: string;
    devDependencies: string;
};

const SpecificNextJsComponent = ({ match }) => {
    const [pageSEO, _setPageSEO] = useState({} as pageSEOInterface);
    const [design, setDesign] = useState<Design | null>(null);
    const [allDesign, setAllDesign] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [previewCode, setPreviewCode] = useState('');

    const fetchDesign = useCallback(async () => {
        const designId = match.params.componentId;
        const designHandler = new functions();
        const allDesign = await designHandler.getAllDesign();
        const selectedDesign = await designHandler.getDesignById(designId);
        if (selectedDesign) {
            selectedDesign.steps = selectedDesign.steps
                ? JSON.parse(selectedDesign.steps)
                : {};

            selectedDesign.cliSteps = selectedDesign.cliSteps
                ? JSON.parse(selectedDesign.cliSteps)
                : {};
            selectedDesign.codeUsage = selectedDesign.codeUsage
                ? JSON.parse(selectedDesign.codeUsage)
                : {};

            let joinCode = Object.keys(selectedDesign.codeUsage || {})
                .filter(
                    (key) =>
                        key !== 'Install dependencies' &&
                        selectedDesign.codeUsage,
                )
                .map((key) => selectedDesign.codeUsage[key].body.join('\n'))
                .join('\n');
            setPreviewCode(joinCode);
            setDesign(selectedDesign);
            setAllDesign(allDesign);
        }

        setIsLoading(false);
    }, [match.params.componentId]);

    useEffect(() => {
        fetchDesign();
    }, [fetchDesign]);

    if (isLoading) {
        return <p>Loading</p>;
    }

    return (
        <React.Fragment>
            <H1Hide content={pageSEO.title} />
            <Headers SEO={pageSEO} />
            <Navbar />
            <div className="nextjs-Container tw-container">
                <div className="nextjs-navContainer webkit-scroll">
                    <div className="nextjsnav">
                        <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                            <span className="black-text font-weight-bold">
                                Follow For Updates
                            </span>
                            <div className="d-flex flex-col">
                                <SidebarItem
                                    text=" Twitter @uday"
                                    link="https://twitter.com/imudayyyy/"
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                            <span className="black-text font-weight-bold filter-heading">
                                Installation
                            </span>
                            {nextInstallationData.map((item, i) => (
                                <SidebarItem
                                    key={i}
                                    text={item.title}
                                    link={`/docs/${item.path}`}
                                />
                            ))}
                        </div>
                        <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                            <a href="/nextjs-component">
                                <span className="black-text font-weight-bold filter-heading">
                                    All Components
                                </span>
                            </a>
                            {allDesign.map((item, i) => (
                                <SidebarItem
                                    key={i}
                                    text={item.title}
                                    link={`/nextjs-component/${item.Id}`}
                                />
                            ))}
                        </div>
                    </div>
                    {/* Design Component */}
                </div>
                <div className="nextjs-designsContainer webkit-scroll">
                    <div className="flex flex-col">
                        <h3 className="margin-left-0 margin-right-0 font-bold">
                            {design.title}
                        </h3>
                        <p className="margin-left-0 light-gray-text margin-right-0">
                            {design.description}
                        </p>
                        {stringUtilsImpl.isUndefinedEmptyorNull(
                            design.creator,
                        ) && design.creator.toLowerCase() !== 'null' ? (
                            <p className="margin-left-0 light-gray-text margin-right-0">
                                Designed by{' '}
                                <a
                                    href={design.creatorProfile}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {design.creator}
                                </a>
                            </p>
                        ) : null}
                    </div>
                    <div className="padding-top-30">
                        <Tabs defaultValue="Preview" className="">
                            <TabsList>
                                <TabsTrigger value="Preview">
                                    Preview
                                </TabsTrigger>
                                <TabsTrigger value="Code">Code</TabsTrigger>
                            </TabsList>
                            <TabsContent value="Preview">
                                <div className="previewUi">
                                    <iframe
                                        className="previewUi"
                                        title="output"
                                        sandbox="allow-scripts"
                                        width="100%"
                                        height="100%"
                                        src={design.hostedURL}
                                    />
                                </div>
                            </TabsContent>
                            <TabsContent value="Code">
                                <HighlightCode code={previewCode} />
                            </TabsContent>
                        </Tabs>
                    </div>
                    <br />
                    <Tabs defaultValue="CLI" className="">
                        <TabsList>
                            <TabsTrigger value="CLI">CLI</TabsTrigger>
                            <TabsTrigger value="Manual">Manual</TabsTrigger>
                        </TabsList>
                        <hr />
                        <TabsContent value="CLI">
                            <div className="steps-container">
                                {Object.keys(design.cliSteps || {}).map(
                                    (step, i) => (
                                        <CodeSteps
                                            key={i}
                                            title={step}
                                            desc={
                                                design.cliSteps[step]
                                                    .description
                                            }
                                            code={design.cliSteps[
                                                step
                                            ].body.join('\n')}
                                        />
                                    ),
                                )}
                            </div>
                        </TabsContent>

                        <TabsContent value="Manual">
                            <div className="steps-container">
                                {design && design.steps.typeScript
                                    ? Object.keys(design.steps.typeScript).map(
                                          (step, i) => (
                                              <CodeSteps
                                                  key={i}
                                                  title={step}
                                                  desc={
                                                      design?.steps.typeScript[
                                                          step
                                                      ].description
                                                  }
                                                  code={
                                                      design?.steps.typeScript[
                                                          step
                                                      ].body.join('\n') || ''
                                                  }
                                              />
                                          ),
                                      )
                                    : ''}
                            </div>
                        </TabsContent>
                    </Tabs>

                    <div className="tw-mt-5">
                        <h4 className="tw-m-0 tw-font-semibold">Usage</h4>
                        <hr />
                        <HighlightCode code={previewCode} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SpecificNextJsComponent;
