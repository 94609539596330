import globalAttributes from "./globalAttributesJson";

const PageSEOData = {
    landingPage: {
        title: "Find Code Snippets for Beautiful Web Designs Components | CodeUI",
        description: "Find high-quality, User centric, mobile responsive web components code. Find components used by LinkedIn, Airbnb, Instamart, Swiggy, SpaceX, and many more. Find code in HTML, CSS, Javascript.",
        image: "https://ik.imagekit.io/c8xfvkbcd/Landing_Page_Images/hero-image.png?updatedAt=1687578867987",
        url: globalAttributes.websiteLink,
        pagetype: "website",
        keywords: "HTML/CSS components, ui library, ui animation library, ui components library, design, HTML, CSS, JAVASCRIPT, REACT, html, css, javascript, react, high quality, mobile responsive",
        canonical: "https://codeui.co.in/"
    },
    serviceLandingPage: {
        title: "Best web design agency in India | CodeUI",
        description: "We are an best creative web design agency in India. We have worked with startup who have recieved funding from amazon to getting aquiredby billion dollar companies",
        image: "https://ik.imagekit.io/c8xfvkbcd/Landing_Page_Images/hero-image.png?updatedAt=1687578867987",
        url: "",
        pagetype: "website",
        keywords: "top web design agency, top web development agency, top web design and development agency, worked with Edubrite, Voxup, Internbucks"
    },
    Design: {
        title: "Discover High Quallity, Mobile Responsiev Code Snippets for Elegant Web Designs | CodeUI",
        description: "Discover stunning code snippets at CodeUI to create captivating web design components. Our curated collection offers pre-built elements like navigation menus, buttons, forms, sliders, and more. Whether you're a seasoned developer or a beginner, our code snippets save you time and effort while ensuring an exceptional user experience. Stay ahead of trends with our regularly updated library. Elevate your web designs with CodeUI today.",
        image: "https://ik.imagekit.io/c8xfvkbcd/Landing_Page_Images/hero-image.png?updatedAt=1687578867987",
        url: "",
        pagetype: "website",
        keywords: ""
    },
    NextjsComponent: {
        title: "",
        description: "",
        image: "",
        url: "",
        pagetype: "website",
        keywords: ""
    },
    specificDesigns: {
        title: "",
        description: "",
        image: "",
        url: "",
        pagetype: "website",
        keywords: ""
    },
    specificDesignFullWindow: {
        title: "",
        description: "",
        image: "",
        url: "",
        pagetype: "website",
        keywords: ""
    },
    Profile: {
        title: "",
        description: "CodeUI user profile",
        image: "",
        url: "",
        pagetype: "website",
        keywords: ""
    },
    LandingPageInspiration: {
        title: "Discover Beautiful Landing Page Inspiration from other websites | CodeUI",
        description: "Find inspiration for beautiful landing pages from various websites. Discover captivating designs, layouts, and compelling content to ignite your creativity. Elevate your own landing page designs with this valuable source of inspiration.",
        image: "https://ik.imagekit.io/c8xfvkbcd/Landing_Page_Images/hero-image.png?updatedAt=1687578867987",
        url: "",
        pagetype: "website",
        keywords: "landing page templates, landindg page designs, startup landing pages, agency landing pages"
    },
    specificLandingPageInspiration: {
        title: "",
        description: "",
        image: "",
        url: "",
        pagetype: "website",
        keywords: "landing page templates"
    },
    Login: {
        title: "Join various designs and developers | CodeUI",
        description: "Join other designers and developers.",
        image: "",
        url: "",
        pagetype: "website",
        keywords: ""
    },
    Blogs: {
        title: "Find Blogs realted to design, development, coding and programming | Builfast",
        description: "Explore our diverse collection of blogs on design, development, coding, and programming. Stay updated, gain insights, and enhance your skills in the ever-evolving tech world. Join our community and stay ahead in the industry.",
        image: "",
        url: "",
        pagetype: "website",
        keywords: "blogs, designs, code, development, technology, tech, UI/UX Designing"
    },
    AddBlog: {
        title: "Add Blogs| CodeUI",
        description: "Add Buildfast Blogs",
        image: "",
        url: "",
        pagetype: "website",
        keywords: "blogs"
    },
    BlogDetail: {
        title: "",
        description: "",
        image: "",
        url: "",
        pagetype: "website",
        keywords: ""
    },
    codeGPTLandingPage: {
        title: "CodeGPT: Let AI do design and coding for you",
        description: "Given Instructions and let AI do the work while you focus on other things",
        image: "",
        url: "",
        pagetype: "website",
        keywords: "codegpt, promomt to code, text to code"
    },
    convertorData: {
        title: "",
        description: "",
        image: "",
        url: "",
        pagetype: "website",
        keywords: ""
    }
}

export default PageSEOData;