import stringUtilsInterface from "utils/stringUtils/stringUtilsInterface";

export default class stringUtilsImpl implements stringUtilsInterface {

    public static isUndefinedEmptyorNull(data) {
        if (data === undefined || data === '' || data === null) {
            return true;
        }
        return false;
    }

}