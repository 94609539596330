import fileUtilsInterface from "./fileUtilsInterface";

export default class fileUtilsImpl implements fileUtilsInterface {
    public static async getBase64(file) {
        return new Promise((resolve, reject) => {
            try {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = function (error) {
                    reject(error);
                };
            } catch (e) {
                reject(e);
            }
        });
    }
}