import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import ConverterImpl from "data/converter/ConverterImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Blogs;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getConvertorDetails(convertorName) {
        let params = { "convertorName": convertorName };
        const result = await axiosCall(ApiRouteMapping.getConvertor.apiPath, ApiRouteMapping.getConvertor.method, params, null, null);
        const convertorHandler = new ConverterImpl();
        convertorHandler.initFromDataObject(result.data.data);
        return convertorHandler;
    }

    async convertSCSSTOCSS(data) {
        let params = {scssCode: data};
        const result = await axiosCall(ApiRouteMapping.scssTOcss.apiPath, ApiRouteMapping.scssTOcss.method, params, null, null);
        return result.data;
    }

    async convertHTMLTOPUG(data) {
        let params = {htmlCode: data};
        const result = await axiosCall(ApiRouteMapping.htmlTopug.apiPath, ApiRouteMapping.htmlTopug.method, params, null, null);
        return result.data;
    }

    async convertHtmltoJsx(data){
        let params = {htmlCode: data};
        const result = await axiosCall(ApiRouteMapping.htmlToJsx.apiPath, ApiRouteMapping.htmlToJsx.method, params, null, null);
        return result.data;
    }
}