import React, { Component } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'components/Tabs/Tabs';

import designFormInterface from 'data/design/designForm/designFormInterface';
import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';

import AdvertiseComponentsProduct from 'components/AdvertiseComponentsProduct';
import AdvertiseCard from 'components/Card/AdvertiseCard';
import DesignCard from 'components/Card/DesignCard';
import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import Headers from 'components/SEO/Headers';
import UserMainProfile from 'components/User/UserMainProfile';
import userFormInterface from 'data/user/userForm/userFormInterface';
import GallaryLayout from 'layouts/GallaryLayout';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import functions from './functions';

export default class Profile extends Component<any, { pageSEO: pageSEOInterface, designs: designFormInterface<minDesignFormInterface>, user: userFormInterface }> {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
            designs: {} as designFormInterface<minDesignFormInterface>,
            user: {} as userFormInterface
        }
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        const profileHandler = new functions();
        const SEO = profileHandler.addSEO();

        let userId = this.props.match.params.userId
        
        const designsResult = await profileHandler.getDesignsbyUser(userId);
        this.setState({
            designs: designsResult
        })

        const userProfile = await profileHandler.getUserProfile();

        SEO.settitle(userProfile.firstname + " "+ userProfile.lastname)
        SEO.setimage(userProfile.image);

        this.setState({
            pageSEO: SEO,
            user: userProfile
        })
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO}/>
                <Navbar />
                <div className="desk-container">
                    <section>
                        <UserMainProfile
                            className="content-center"
                            user={this.state.user}
                            size="180px"
                        />
                    </section>

                    <section>
                        <div className="Row">
                            <div className="Column-3">
                                {
                                    this.state.user.subscriptionType === 'BASIC' ?
                                        <AdvertiseCard
                                        />
                                        :
                                        <AdvertiseComponentsProduct
                                            heading="Request a component"
                                            href="mailto:uday@buildfast.co.in"
                                            ctaText="request"
                                        />
                                }
                            </div>
                            <div className="Column-9">
                                <Tabs selected={0}>
                                    <TabList tabName="TabList">
                                        <Tab tabName="Tab">
                                            {/* eslint-disable-next-line */}
                                            <a className="font-18">Designs</a>
                                        </Tab>
                                        <Tab tabName="Tab">
                                            {/* eslint-disable-next-line */}
                                            <a className="font-18">Payments</a>
                                        </Tab>
                                    </TabList>
                                    <hr />
                                    <TabPanel tabName="TabPanel">
                                        <GallaryLayout>
                                            {
                                                !jsonUtilsImpl.isEmpty(this.state.designs)
                                                    ?
                                                    this.state.designs.designs.map((design) => {
                                                        return (
                                                            <>
                                                                <div className="grid-item photo">
                                                                    <DesignCard
                                                                        design={design}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <>
                                                        <p className='text-danger'> No Designs posted right now. </p>
                                                    </>
                                            }
                                        </GallaryLayout>
                                    </TabPanel>

                                    <TabPanel tabName="TabPanel">
                                        Related to bar
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        )
    }
}
