import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import blogDetailFormImpl from "data/blog/blogDetailForm/blogDetailFormImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.BlogDetail;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getBlogbyId(Id) {
        let params = {blogId: Id}
        let result = await axiosCall(ApiRouteMapping.getBlogbyId.apiPath, "GET", params);
        const blog = new blogDetailFormImpl();
        blog.initFromDataObject(result.data.blog);
        return blog;
    }
}