import React, { Component } from 'react';

export default class Button extends Component<
    { id: string; className: string; text: string; type: any },
    {}
> {
    render() {
        return (
            <button
                id={this.props.id}
                className={this.props.className}
                type={this.props.type}
            >
                {this.props.text}
            </button>
        );
    }
}
