import * as React from 'react';
import { cn } from 'utils/cn';

type PrimaryLinkProps = {
    variant?: 'outline' | 'link';
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;
export default function PrimaryLink({
    children,
    className,
    variant,
    ...props
}: PrimaryLinkProps) {
    let defaultStyle =
        'tw-text-white tw-bg-blue hover:tw-bg-transparent hover:tw-text-blue ';
    if (variant) {
        if (variant === 'outline')
            defaultStyle =
                'tw-text-blue tw-bg-transparent hover:tw-bg-blue hover:tw-text-white ';
        if (variant === 'link')
            defaultStyle =
                'tw-text-black/80 hover:tw-text-blue tw-p-0 tw-rounded-none ';
    }

    return (
        <a
            {...props}
            className={cn(
                'tw-text-sm tw-text-center tw-rounded-full tw-py-2.5 tw-px-5 tw-transition-all tw-duration-200 tw-ease-in-out tw-cursor-pointer  tw-flex tw-items-center tw-justify-center',
                defaultStyle,
                className,
                { 'tw-border tw-border-blue': variant !== 'link' },
            )}
            target={props.target}
            href={props.href}
            onClick={props.onClick}
        >
            {children}
        </a>
    );
}
