import React, { Component } from 'react'
import Tags from '../Button/Tags'
import ImgRounded from '../Image/ImgRounded'
import userFormInterface from 'data/user/userForm/userFormInterface';
import dateUtilsImpl from 'utils/dateUtils/dateUtilsImpl';

export default class UserMainProfile extends Component<{ className: string, user: userFormInterface, size: string }, {}> {
  render() {
    return (
      <div className={this.props.className !== '' || this.props.className !== undefined ? "d-flex items-center "+ this.props.className: "d-flex items-center "}>
        <div className='margin-right-20'>
            <ImgRounded src={this.props.user.image} size={this.props.size} />
        </div>
        <div> 
            <h6>{this.props.user.firstname} {this.props.user.lastname}</h6>
            <p>Created On {dateUtilsImpl.formatDate(this.props.user.createdAt)}</p>
            <Tags 
              className="tag margin-left-20 margin-top-20"
              text={this.props.user.subscriptionType}
            />
        </div>
      </div>
    )
  }
}
