import PrimaryLink from 'components/Button/PrimaryLink';
import Footer from 'components/Navbars/Footer';
import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import designFormInterface from 'data/design/designForm/designFormInterface';
import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface';
import GetFeebackFloatingButton from 'features/feedbackFloatingButton/GetFeebackFloatingButton';
import 'landingPage.css';
import React, { Component } from 'react';
import Hero from './components/Hero';
import PricingTable from './components/PricingTable';
import ServicesGrid from './components/ServicesGrid';
import functions from './functions';

export default class landingPage extends Component<
    any,
    {
        pageSEO: pageSEOInterface;
        designs: designFormInterface<minDesignFormInterface>;
    }
> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
            designs: {} as designFormInterface<minDesignFormInterface>,
        };
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {
        const landingPageHandler = new functions();

        const SEO = landingPageHandler.addSEO();
        this.setState({ pageSEO: SEO });

        const designs = await landingPageHandler.getDesigns();
        this.setState({ designs });
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <Hero />
                <ServicesGrid />
                <PricingTable />
                <div>
                    <div
                        id="end-section"
                        className="tw-py-[189px] tw-bg-blue-light inter"
                    >
                        <div className="flex tw-items-center tw-justify-center tw-flex-col tw-gap-4">
                            <div className="tw-font-bold tw-text-4xl md:tw-text-[58px] md:tw-leading-[65px] text-black-light tw-tracking-[-1.92px] tw-w-8/12 md:tw-w-1/2 tw-text-center">
                                Didn't find the component that you are looking
                                for?
                            </div>
                            <PrimaryLink
                                id="launchBtn5"
                                href="mailto:uday@buildfast.co.in?Subject=Requesting%20a%20code%20for%20a%20Design"
                                className="tw-text-base tw-py-[13px] tw-font-medium tw-mt-[25px]"
                            >
                                Request a component
                            </PrimaryLink>
                        </div>
                    </div>
                </div>
                <div>
                    <GetFeebackFloatingButton />
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
