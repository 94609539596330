import React, { Component } from 'react';

//Components
import Headers from '../../components/SEO/Headers';

// Functions
import functions from './functions';

import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import designCategoriesFormInterface from 'data/design/designCategoriesForm/designCategoriesFormInterface';
import designFormInterface from 'data/design/designForm/designFormInterface';
import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';

import PrimaryLink from 'components/Button/PrimaryLink';
import DesignCard from 'components/Card/DesignCard';
import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import ReactSelectInterface from 'data/ReactSelect/ReactSelectInterface';
import designFiltersFormInterface from 'data/design/designFilters/designFiltersFormInterface';
import GallaryLayout from 'layouts/GallaryLayout';
interface DesignList {
    Id: string;
    title: string;
    description: string;
    imageURL: string;
    steps: string;
}
export default class Designs extends Component<
    {},
    {
        pageSEO: pageSEOInterface;
        designs: designFormInterface<minDesignFormInterface>;
        nextjsDesigns: DesignList[];
        categories: designCategoriesFormInterface[];
        filters: designFiltersFormInterface;
        designType: ReactSelectInterface[];
        isLoading: boolean;
        currentCategoryId: string;
        currentType: string;
    }
> {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.setDesign = this.setDesign.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickCategory = this.onClickCategory.bind(this);

        this.state = {
            pageSEO: {} as pageSEOInterface,
            designs: {} as designFormInterface<minDesignFormInterface>,
            nextjsDesigns: [] as any,
            categories: [] as designCategoriesFormInterface[],
            designType: [] as any,
            filters: {} as designFiltersFormInterface,
            isLoading: true as boolean,
            currentCategoryId: '' as string,
            currentType: '' as string,
        };
    }

    componentWillMount() {
        this.init();
    }

    async init() {
        const designHandler = new functions();
        const SEO = designHandler.addSEO();
        this.setState({ pageSEO: SEO });

        this.setDesign();

        const categoriesResult = await designHandler.getCategories();
        this.setState({
            categories: categoriesResult,
        });

        const designTypeResult = await designHandler.getDesignType();
        this.setState({
            designType: designTypeResult,
        });
    }

    async setDesign(filters = undefined) {
        const designHandler = new functions();
        const designsResult = await designHandler.getDesigns(filters);
        const componentResult = await designHandler.getNextjsComponent();

        this.setState({
            designs: designsResult,
            nextjsDesigns: componentResult,
        });
        this.toogleisLoading();
    }

    async onClickCategory(e, categoryId, type) {
        e.preventDefault();
        await this.toogleisLoading();
        const designHandler = new functions();
        const designsResult = await designHandler.getDesignsWithCategories(
            categoryId,
            type,
        );
        this.setState({
            designs: designsResult,
            currentCategoryId: categoryId,
            currentType: type,
        });
        await this.toogleisLoading();
    }

    async onChangeHandler(e, editorName) {
        this.toogleisLoading();
        this.setState({
            isLoading: true,
        });

        let name = '';
        let value = '';
        if (editorName === 'category' || editorName === 'type') {
            name = editorName;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        await this.setState((prevState) => ({
            filters: {
                ...prevState.filters,
                [name]: value,
            },
        }));

        this.setDesign(this.state.filters);
    }

    toogleisLoading() {
        this.setState((prevState) => ({
            isLoading: !prevState.isLoading,
        }));
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="tw-grid md:tw-grid-cols-[25%_75%] tw-grid-rows-1 tw-h-screen ">
                    {/* sidebar */}
                    <div className="tw-hidden md:tw-block tw-w-full  tw-border-r-[0.5px] tw-border-[#000000]/10 tw-h-full tw-shadow-md tw-relative tw-overflow-hidden">
                        <div className="w-full tw-h-full tw-sticky tw-top-[80px] tw-overflow-y-auto tw-pl-[50px] tw-pr-[20px] tw-py-[42px] webkit-scroll">
                            {Object.keys(this.state.designType).map((type) => {
                                return (
                                    <div className="tw-mb-[30px]">
                                        <h4 className="tw-m-0 tw-py-[5px] tw-rounded-[4px] tw-text-black tw-text-sm tw-font-medium tw-mb-[5px]">
                                            {type}
                                        </h4>
                                        {this.state.designType[type].map(
                                            (category) => {
                                                return (
                                                    <div
                                                        className={
                                                            this.state
                                                                .currentCategoryId ===
                                                                category.Id &&
                                                            this.state
                                                                .currentType ===
                                                                type
                                                                ? 'tw-bg-[#316BE81A] hover:tw-bg-[#316BE81A] tw-text-[#000000CC] tw-m-0 py-[5px] tw-px-[10px] tw-rounded-[4px] tw-flex tw-items-center tw-cursor-pointer tw-my-1'
                                                                : 'hover:tw-bg-[#316BE81A] tw-text-[#000000CC] tw-m-0 py-[5px] tw-px-[10px] tw-rounded-[4px] tw-flex tw-items-center tw-cursor-pointer tw-my-1'
                                                        }
                                                        onClick={(e) => {
                                                            this.onClickCategory(
                                                                e,
                                                                category.Id,
                                                                type,
                                                            );
                                                        }}
                                                    >
                                                        <PrimaryLink
                                                            className="tw-text-sm hover:tw-text-black tw-font-normal"
                                                            variant="link"
                                                        >
                                                            {category.name}
                                                        </PrimaryLink>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/*Design cards */}
                    <div className="tw-w-full tw-overflow-hidden">
                        <div className="tw-overflow-y-auto tw-h-full tw-w-full tw-p-5 webkit-scroll tw-flex tw-flex-col">
                            <GallaryLayout>
                                {this.state.nextjsDesigns.map((items, i) => (
                                      
                                        <div className="grid-item photo">
                                            <a
                                                href={
                                                    '/nextjs-component/' +
                                                    items.Id
                                                }
                                                className="Link"
                                            >
                                                <div className="Card shadow-small shadow-onHover-medium">
                                                    <div className="Card-body padding-0">
                                                       

                                                        <div className="CardImg-container">
                                                          
                                                            <img
                                                                src={
                                                                    items.imageURL
                                                                }
                                                                className="design-Image"
                                                                alt={
                                                                    items.title
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="Card-footer">
                                                        <div className="d-flex space-between items-center">
                                                            <div className="d-flex items-center">
                                                                <p className="margin-0 margin-left-10">
                                                                    {
                                                                        items.title
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}

                              
                                {this.state.isLoading ? (
                                    <>
                                        <p className="text-danger">
                                            Loading...
                                        </p>
                                    </>
                                ) : !jsonUtilsImpl.isEmpty(
                                      this.state.designs,
                                  ) &&
                                  !jsonUtilsImpl.isEmpty(
                                      this.state.designs.designs,
                                  ) ? (
                                    this.state.designs.designs.map((design) => {
                                        return (
                                            <>
                                                <div className="grid-item photo">
                                                    <DesignCard
                                                        design={design}
                                                    />
                                                </div>
                                            </>
                                        );
                                    })
                                ) : (
                                    <>
                                        <p className="text-danger tw-p-0 tw-m-0">
                                            No Designs Available
                                        </p>
                                    </>
                                )}
                            </GallaryLayout>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
