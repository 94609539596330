//@ts-nocheck
import beautify from 'js-beautify';
import pugBeautify from 'pug-beautify'
import { CssToTailwindTranslator } from "css-to-tailwind-translator";


import languageENUMS from 'data/ENUMS/languageENUMS';

export default class codeHelper {
    static async preetifyCode(code: string, language: languageENUMS) {
        try {
            if (language === languageENUMS.CSS) {
                return beautify.css(code);
            }
            if(language === languageENUMS.JSX) {
                return beautify.html(code)
            }
            if (language === languageENUMS.JAVASCRIPT) {
                return beautify.html(code)
            }
            if(language === languageENUMS.PUG) {
                return pugBeautify.html(code)
            }
            if(language === languageENUMS.JSONFORMATTER) {
                const parsedJson = JSON.parse(code);
                const beautified = JSON.stringify(parsedJson, null, 2);
                return beautified;
            }
            if(language === languageENUMS.TAILWIND) {
                const conversionResult = CssToTailwindTranslator(code);
                return conversionResult;
            }
        } catch (error) {
            return code;
        }
    }

    static preetifyCodeWithoutLang(code: string) {
        return beautify.js(code);
        // return beautify.beautify(code);
    }
}