import React, { Component } from 'react'

import functions from './functions';

import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import Headers from 'components/SEO/Headers';
import Input from 'components/InputGroup/Input';
import Button from 'components/Button/Button';
import TinyMCEEditor from 'lib/TinyMCEEditor';
import blogDetailFormInterface from 'data/blog/blogDetailForm/blogDetailFormInterface';
import fileUtilsImpl from 'utils/fileUtils/fileUtilsImpl';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import H1Hide from 'components/SEO/H1Hide';
import Navbar from 'components/Navbars/Navbar';

export default class Blogs extends Component<any, { pageSEO: pageSEOInterface, blog: blogDetailFormInterface, imageFiles: any }> {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        
        this.state = {
            pageSEO: {} as pageSEOInterface,
            blog: {} as blogDetailFormInterface,
            imageFiles: []
        }
    }

    componentDidMount(): void {
        this.init();
    }

    async onDrop(file) {
        const fileBase64 = await fileUtilsImpl.getBase64(file[0]);
        const imageJson = {
            base64: fileBase64,
            filename: file[0].name
        }
        const imageJsonString = jsonUtilsImpl.toString(imageJson);

        this.setState((prevState) => ({
            blog: {
                ...prevState.blog,
                image: imageJsonString,
            },
            imageFiles: file
        }));
    };

    init() {
        const AddBlogHandler = new functions();

        const SEO = AddBlogHandler.addSEO();
        this.setState({ pageSEO: SEO });
    }

    onChangeHandler(e, editorName) {
        let name = '';
        let value = '';
        if (editorName === 'content') {
            name = editorName;
            value = e;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        this.setState((prevState) => ({
            blog: {
                ...prevState.blog,
                [name]: value,
            },
        }));
    }

    async onSubmit(e) {
        e.preventDefault();

        const AddBlogHandler = new functions();
        await AddBlogHandler.addBlog(this.state.blog)
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                {/* <GeneralNavbar /> */}
                <Navbar />
                <div className="desk-container" style={{backgroundColor: '#fff'}}>
                    <section>
                        <div className="Row content-center">
                            <div className="Column-6">
                                <form onSubmit={this.onSubmit}>
                                    <Input
                                        label="Title"
                                        name="title"
                                        onChange={this.onChangeHandler}
                                        required = {1}
                                    />

                                    <Input
                                        label="Image Link"
                                        name="image"
                                        onChange={this.onChangeHandler}
                                        required = {1}
                                    />
                                    

                                    <Input
                                        label="Content"
                                    >
                                        <TinyMCEEditor 
                                            onEditorChange = {(e) => this.onChangeHandler(e, 'content')}
                                            textareaName = "content"
                                        />
                                        {/* <MantineEditor /> */}
                                    </Input>

                                    <Input
                                        label="Tags"
                                        name="tags"
                                        onChange={this.onChangeHandler}
                                        required={1}
                                    />

                                    <Button
                                        id={undefined}
                                        text="Submit"
                                        className="btn btn-medium shadow-small btn-primary"
                                        type="submit"
                                    />

                                </form>
                            </div>
                        </div>
                        
                    </section>
                </div>
            </React.Fragment>
        )
    }
}
