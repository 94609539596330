import jsonUtilsInterface from "utils/jsonUtils/jsonUtilsInterface";

export default class jsonUtilsImpl implements jsonUtilsInterface {

    public static isEmpty(data) {
        if (Object.keys(data).length === 0) {
            return true;
        }
        return false;
    }

    static toString(json) {
        return JSON.stringify(json);
    }
}