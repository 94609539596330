import React, { Component } from 'react'

export class ButtonwithIcon extends Component<{ text: string, className: string, designId: string}> {

  redirectOnClick(designId) {
    let url = '/show-full-design/' + designId;
    window.open(url, '_blank');
  };

  render() {
    return (
      <button className={this.props.className} onClick={() => this.redirectOnClick(this.props.designId)}>
        <span>{this.props.text}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM168,144a8,8,0,0,1-16,0V115.31l-50.34,50.35a8,8,0,0,1-11.32-11.32L140.69,104H112a8,8,0,0,1,0-16h48a8,8,0,0,1,8,8Z"></path></svg>
      </button>
    )
  }
}

export default ButtonwithIcon;