import React, { Component } from 'react'

export default class ImgRounded extends Component {
  render() {
    return (
        <img 
            src={this.props.src}
            width={this.props.size} 
            height={this.props.size} 
            className='roundedImg border-gray800'
            alt = {this.props.alt}
        />
    )
  }
}
