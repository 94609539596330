import pageSEOImpl from 'data/SEO/pageSEO/pageSEOImpl';
import designCategoriesFormImpl from 'data/design/designCategoriesForm/designCategoriesFormImpl';
import axiosCall from 'lib/axios';
import ApiRouteMapping from 'utils/AllApiRouteMapping';
import PageSEOData from 'utils/AllPageSEOData';

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.specificDesigns;
        pageSEO.initFromDataObject(data);
        return pageSEO;
    }

    async getCategories() {
        let result = await axiosCall(
            ApiRouteMapping.getAllCategories.apiPath,
            'GET',
        );
        const categories = [];

        for (let i = 0; i < result.data.categories.length; i++) {
            const designCategoriesForm = new designCategoriesFormImpl();
            designCategoriesForm.initDataFromObject(result.data.categories[i]);
            categories.push(designCategoriesForm);
        }

        return categories;
    }

    async getDesignType() {
        let result = await axiosCall(
            ApiRouteMapping.getAllDesignType.apiPath,
            'GET',
        );
        if (result.data.success) {
            return result.data.type;
        }
    }

    async addPost(dataJson) {
        const payload = dataJson;

        let result = await axiosCall(
            ApiRouteMapping.submitDesign.apiPath,
            'POST',
            null,
            null,
            payload,
        );
    }
}
