import React, { Component } from 'react'
import Select from 'react-select'
import $ from 'jquery';
import Button from 'components/Button/Button';
import Input from 'components/InputGroup/Input';
import feedbackFormInterface from 'data/feedback/feedbackFormInterface';
import functions from './functions';

const feedback_options = [
  { value: 'FEEDBACK', label: 'Feedback' },
  { value: 'IDEA', label: 'Idea' }
]

export default class GetFeebackFloatingButton extends Component<any, { feedback: feedbackFormInterface, is_success: string, responsemsg: string}> {
    constructor(props) {
        super(props);
        
        this.submitFeedback = this.submitFeedback.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleTogglebutton = this.handleTogglebutton.bind(this);
        this.state= {
            feedback: {} as feedbackFormInterface ,
            is_success: '',
            responsemsg: ''
        }
    }

    async onChangeHandler(e, editorName = undefined) {
        let name = '';
        let value = '';
        if (editorName === 'type') {
            name = editorName;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        await this.setState((prevState) => ({
            feedback: {
                ...prevState.feedback,
                [name]: value,
            },
        }));
    }

    async submitFeedback(e) {
        e.preventDefault();
        const AddFeebackHandler = new functions();
        const blog = await AddFeebackHandler.submitFeeback(this.state.feedback)
        if (blog.data.success) {
            this.setState({
                is_success: 'text-success',
                responsemsg: blog.data.msg
            })
        }
    }

    handleTogglebutton() {
        $('.ba-we-love-subscribers-fab .wrap').toggleClass("ani");
        $('.ba-we-love-subscribers').toggleClass("open");
        $('.img-fab.img').toggleClass("close");
    }

    render() {
        return (
        <div>
            <div className="ba-we-love-subscribers-wrap">
                <div className="ba-we-love-subscribers popup-ani">
                    <div className="ba-we-love-subscribers-container">
                        <div>
                            <h4>We do what our customers want.</h4>
                        </div>
                        <div onSubmit={this.submitFeedback}>
                            <form style={{margin: "30px", justifyContent: 'left', alignItems: "left"}}>
                                <Input label="type">
                                    <Select
                                        options={feedback_options}
                                        name="type"
                                            onChange={(e) => this.onChangeHandler(e, 'type')}
                                    />
                                </Input>
                                <Input label="Details">
                                    <textarea onChange={(e) => this.onChangeHandler(e)} name="details"></textarea>
                                </Input>
                                <div className='margin-top-20'>
                                <Button id="launchBtn5" type="submit"
                                    className="btn btn-small shadow-small btn-primary" text="Submit"/>
                                </div>
                                <div className="mt-3 mb-5">    
                                    <span className={this.state.is_success}>{this.state.responsemsg}</span>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
                <div className="ba-we-love-subscribers-fab" onClick={this.handleTogglebutton}>
                    <div className="wrap">
                        <div className="img-fab img"></div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
