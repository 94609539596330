import React, { Component } from 'react';
import functions from './functions';
export default class Pricing extends Component {
    async onSubmit() {
        const checkout = new functions();
        const url = await checkout.createCheckout();
        window.location.href = url;
    }
    render() {
        return (
            <React.Fragment>
                <div className="flex-vertical-center-center padding-y-60 wf-section">
                    <h1>Pricing</h1>
                    <div className="d-flex gap-20">
                        <div className="border rounded-lg padding-30 d-flex flex-col gap-20 w-full">
                            <h2 className="margin-0">Basic</h2>
                            <h3 className="margin-0">INR 0/m</h3>
                            <ul>
                                <li>Free Web Component</li>
                                <li>Buildfast Plugin</li>
                            </ul>
                        </div>
                        <div className="border rounded-lg padding-30 d-flex flex-col gap-20 w-full">
                            <h2 className="margin-0 pb-2">Premium</h2>
                            <h3 className="margin-0 pb-2">INR 999/m</h3>
                            <ul className="">
                                <li>Free & Premium Web Component</li>
                                <li>Buildfast Plugin</li>
                                <li>5 Custom web components code</li>
                                <li>1 Landing page design & code</li>
                            </ul>
                            <button
                                onClick={this.onSubmit}
                                className="btn-secondary rounded-md pt-2 pb-2"
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
