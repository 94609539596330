import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.specificDesigns;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl())
        return pageSEO;
    }
}