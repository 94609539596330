import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";

import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.AddBlog;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async addBlog(dataJson) {
        const payload = {
            data: dataJson,
        }
        let result = await axiosCall(ApiRouteMapping.addBlog.apiPath, "POST", null, null, payload);
    
    }
}