import designInspirationDetailImpl from "../designInspirationDetailForm/designInspirationDetailImpl";
import designInspirationDetailInterface from "../designInspirationDetailForm/designInspirationDetailInterface";
import designInspirationInterface from "./designInspirationInterface";

export default class designInspirationImpl implements designInspirationInterface{
    designInspirations: designInspirationDetailInterface[] = [];
    itemsFetched: number = 0;
    fetchFrom: any = new Date();
    totalItems: number;
    count: number = 15;
    hasmore: boolean = true;


    initFromDataObject(data) {
        for (let i = 0; i < data.length; i++) {
            let designInspirationDetail = new designInspirationDetailImpl();
            designInspirationDetail.initFromDataObject(data[i]);
            this.designInspirations.push(designInspirationDetail);
        }
    }

    getfetchFrom() {
        return this.fetchFrom;
    }

    getdesignInspirations() {
        return this.designInspirations;
    }

    setitemsFetched(itemsFetched) {
        this.itemsFetched = itemsFetched;
    }

    getitemsFetched() {
        return this.itemsFetched;
    }

    gettotalItems() {
        return this.totalItems;
    }

    getcount() {
        return this.count;
    }

    gethasmore() {
        return this.hasmore;
    }
    
    sethasmore(value: boolean) {
        this.hasmore = value;
    }
} 