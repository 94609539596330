import React, { Component } from 'react'
import Button from '../Button/Button'

export default class AdvertiseCard extends Component {
  render() {
    return (
        <div className="Card shadow-small shadow-onHover-medium sticky-top">
          <div className="Card-body">
              <p className="margin-left-0 margin-top-0 font-weight-bold">Didn't see a code for your design?</p>
              <p className="margin-left-0">Checkout out premium Now!!</p>
              <Button 
                  className="btn btn-medium"
                  text = "BUY PREMIUM NOW"
              />
          </div>
      </div>
    )
  }
}
