import blogDetailFormImpl  from "../blogDetailForm/blogDetailFormImpl";
import blogDetailFormInterface from "../blogDetailForm/blogDetailFormInterface";
import blogFormInterface from "./blogFormInterface";

export class blogFormImpl implements blogFormInterface {
    blogs: blogDetailFormInterface[] = [];

    initFromDataObject(data) {
        for (let i = 0; i < data.length; i++) {
            let blogDetail = new blogDetailFormImpl();
            blogDetail.initFromDataObject(data[i]);
            this.blogs.push(blogDetail);
        }
    }
}