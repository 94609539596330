import React, { Component } from 'react'
import ImgRounded from '../Image/ImgRounded'

export default class UserProfile extends Component<{user: any, size: number, name: string}, any> {
  render() {
    return (
      <div className="d-flex items-center">
        <div>
            <ImgRounded src={this.props.user.image} size={this.props.size}/>
        </div>
        <div>
            <p>{this.props.name}</p>
            <p className='p-small'>{this.props.user.firstname + ' ' + this.props.user.lastname}</p>
        </div>
      </div>
    )
  }
}
