import pageSEOImpl from 'data/SEO/pageSEO/pageSEOImpl';
import designFormImpl from 'data/design/designForm/designFormImpl';
import minDesignFormImpl from 'data/design/minDesignForm/minDesignFormImpl';
import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface';
import axiosCall from 'lib/axios';
import ApiRouteMapping from 'utils/AllApiRouteMapping';
import PageSEOData from 'utils/AllPageSEOData';

export default class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.landingPage;
        pageSEO.initFromDataObject(data);
        return pageSEO;
    }

    async getDesigns(filters = undefined) {
        let result = await axiosCall(
            ApiRouteMapping.getDesignsByArray.apiPath,
            'GET',
            filters,
        );
        const designs = await this.convertAPIresultToFormModal(result);
        return designs;
    }

    convertAPIresultToFormModal(data) {
        const designs = new designFormImpl<minDesignFormInterface>();
        designs.initFromDataObject(
            data.data.designs.designs,
            minDesignFormImpl,
        );
        return designs;
    }
}
