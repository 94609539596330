import React, { Component } from 'react'
import dateUtilsImpl from 'utils/dateUtils/dateUtilsImpl';
import globalAttributes from 'utils/globalAttributesJson';

export default class BlogCard extends Component<any, any> {
    render() {
        return (
            <div className="buildfast-margin-20">
                <a href={"/blog-detail/" + this.props.blog.Id} className="buildfast-a-tag">
                    <div>
                        <div className="buildfast-blog-image buildfast-margin-b-10">
                            <img
                                alt={this.props.blog.title + " | " + globalAttributes.companyName}
                                src={this.props.blog.image} />
                            <div
                                className="buildfast-blog-details buildfast-blog-posted-date buildfast-margin-b-10">
                                {dateUtilsImpl.formatDate(this.props.blog.createdAt)}
                            </div>
                            <div className="buildfast-blog-tiltle">
                                <h4>
                                    {this.props.blog.title}
                                </h4>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}
