import React, { Component } from 'react'

export default class Tags extends Component {
  render() {
    return (
      // eslint-disable-next-line
      <a className={this.props.className} href="javascript:;">
        <small>{this.props.text}</small>
      </a>
    )
  }
}
