import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

export default class functions {

    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Login;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl())
        return pageSEO;
    }

    async loginUser(user) {
        let headersJson = {
            headers: {
                'x-auth-token': user.credential
            } 
        }

        let result = await axiosCall(ApiRouteMapping.loginUser.apiPath, "POST", null, headersJson, null);
        return result.data;
    } 
}