import axiosCall from 'lib/axios';
import ApiRouteMapping from 'utils/AllApiRouteMapping';

export default class functions {
    async createCheckout() {
        let result = await axiosCall(
            ApiRouteMapping.getCheckoutUrl.apiPath,
            'GET',
        );
        return result.data.url;
    }
}
