import PrimaryLink from 'components/Button/PrimaryLink';
import React from 'react';

const SocialIcons = [
    {
        name: 'Facebook',
        icon: 'https://ik.imagekit.io/codeui/CODE%20UI/Facebook.svg?updatedAt=1716014549250',
        link: '',
    },
    {
        name: 'Instagram',
        icon: 'https://ik.imagekit.io/codeui/CODE%20UI/Instagram.svg?updatedAt=1716014549463',
        link: '',
    },
    {
        name: 'Dribble',
        icon: 'https://ik.imagekit.io/codeui/CODE%20UI/Dribbble.svg?updatedAt=1716014548939',
        link: '',
    },
    {
        name: 'Youtube',
        icon: 'https://ik.imagekit.io/codeui/CODE%20UI/YouTube.svg?updatedAt=1716014549159',
        link: '',
    },
    {
        name: 'Twitter',
        icon: 'https://ik.imagekit.io/codeui/CODE%20UI/Vector.svg?updatedAt=1716014549178',
        link: '',
    },
];

export default function Footer() {
    return (
        <div className="tw-bg-white tw-pt-[50px] tw-pb-36 tw-p-8 md:p-0  jakarta-sans">
            <div className="tw-flex tw-flex-col md:tw-flex-row  tw-gap-5 md:gap-0 tw-items-center tw-justify-between tw-container tw-mx-auto tw-pb-[30px] tw-border-b-[0.6px] tw-border-[#DAD5D5CC]">
                <div className="w-full md:tw-max-w-[350px]  tw-flex tw-flex-col tw-justify-center tw-items-start tw-gap-5">
                    <a href="/">
                        <img
                            src="https://ik.imagekit.io/codeui/CODE%20UI/codeuilogo?updatedAt=1715879107419"
                            className="h-[30px]"
                            alt=""
                        />
                    </a>
                    <p className="tw-p-0 tw-m-0 tw-text-grey-dark tw-text-base">
                        Find beautifully designed mobile responsive components
                        that your can directly copy paste into your projects.
                    </p>
                    <PrimaryLink href="/designs">Component Library</PrimaryLink>
                </div>
                <div className="w-full md:tw-max-w-[350px] tw-flex tw-justify-between ">
                    <div className="tw-flex tw-flex-col">
                        <h4 className="tw-text-xl tw-text-black-light tw-font-bold tw-p-0 tw-m-0">
                            About Us
                        </h4>
                        <ul className="tw-mt-5 md:tw-mt-[30px] tw-space-y-[15px]">
                            <li>
                                <a
                                    className="tw-text-black-light/80 tw-text-base"
                                    href="/"
                                >
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a
                                    className="tw-text-black-light/80 tw-text-base"
                                    href="/"
                                >
                                    Affiliate Program
                                </a>
                            </li>
                            <li>
                                <a
                                    className="tw-text-black-light/80 tw-text-base"
                                    href="/"
                                >
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                <a
                                    className="tw-text-black-light/80 tw-text-base"
                                    href="/"
                                >
                                    Demo Video
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tw-flex tw-flex-col">
                        <h4 className="tw-text-xl tw-text-black-light tw-font-bold tw-p-0 tw-m-0">
                            Legal
                        </h4>
                        <ul className="tw-mt-5 md:tw-mt-[30px] tw-space-y-[15px]">
                            <li>
                                <a
                                    className="tw-text-black-light/80 tw-text-base"
                                    href="/"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    className="tw-text-black-light/80 tw-text-base"
                                    href="/"
                                >
                                    Terms and Conditions
                                </a>
                            </li>
                            <li>
                                <a
                                    className="tw-text-black-light/80 tw-text-base"
                                    href="/"
                                >
                                    Cancellation policy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-5 md:tw-gap-0 tw-justify-between tw-container tw-items-center  tw-mx-auto tw-pt-[30px]">
                <p className="tw-m-0 tw-p-0 tw-text-black-light/80 tw-text-base">
                    © CodeUi. All Rights Reserved.
                </p>
                <div className="tw-flex tw-gap-[25px] tw-cursor-pointer tw-items-center">
                    {SocialIcons.map((item, index) => (
                        <a key={item.name} href={item.link}>
                            <img
                                className={`tw-size-[25px] tw-object-cover tw-hover:scale-105 tw-cursor-pointer ${
                                    index === SocialIcons.length - 1
                                        ? 'tw-size-[22px]'
                                        : ''
                                }`}
                                src={item.icon}
                                alt={item.name}
                            />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}
