import React, { Component } from 'react';

import functions from './functions';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import designInspirationDetailInterface from 'data/designInspiration/designInspirationDetailForm/designInspirationDetailInterface';
import AdvertiseComponentsProduct from 'components/AdvertiseComponentsProduct';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import H1Hide from 'components/SEO/H1Hide';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';
import Navbar from 'components/Navbars/Navbar';

export default class specificLandingPageInspiration extends Component<
    any,
    {
        pageSEO: pageSEOInterface;
        designInspiration: designInspirationDetailInterface;
    }
> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);

        this.state = {
            pageSEO: {} as pageSEOInterface,
            designInspiration: {} as designInspirationDetailInterface,
        };
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {
        const specificLandingPageInspirationHandler = new functions();
        const SEO = specificLandingPageInspirationHandler.addSEO();

        let designInspirationId = this.props.match.params.inspirationId;
        const designInspirationForm =
            await specificLandingPageInspirationHandler.getDesignInpirationbyId(
                designInspirationId,
            );

        SEO.settitle(designInspirationForm.Productname);
        SEO.setdescription(designInspirationForm.description);
        SEO.setimage(designInspirationForm.desktopViewImage);

        this.setState({
            pageSEO: SEO,
            designInspiration: designInspirationForm,
        });
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="desk-container">
                    <section>
                        {!jsonUtilsImpl.isEmpty(
                            this.state.designInspiration,
                        ) ? (
                            <div className="product-details">
                                <div>
                                    <h6>
                                        {
                                            this.state.designInspiration
                                                .Productname
                                        }
                                    </h6>
                                    <p>
                                        {!stringUtilsImpl.isUndefinedEmptyorNull(
                                            this.state.designInspiration
                                                ?.description,
                                        ) ? (
                                            <>
                                                {
                                                    this.state.designInspiration
                                                        ?.description
                                                }
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </p>
                                </div>
                                <div className="visit-product-site-button">
                                    <a
                                        href={decodeURIComponent(
                                            this.state.designInspiration
                                                .websitelink,
                                        )}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="sb-cta sb-cta-modifier-blue margin-top_20 sb-marginsides-5 w-button"
                                    >
                                        Visit Site
                                    </a>
                                </div>

                                <div>
                                    <AdvertiseComponentsProduct
                                        heading="Extensive resonsive web component from big tech companies"
                                        description="Design your website fast, choosing the componenet that you want to use."
                                        href="/designs"
                                        ctaText="Browse Components"
                                    />
                                </div>

                                <div style={{ marginBottom: '100px' }}>
                                    <div className="card mt-5">
                                        <a
                                            href={decodeURIComponent(
                                                this.state.designInspiration
                                                    .websitelink,
                                            )}
                                        >
                                            <div className="card-body p-0">
                                                <img
                                                    src={
                                                        this.state.designInspiration.desktopViewImage.includes(
                                                            'DesignInspiration',
                                                        )
                                                            ? 'https://ik.imagekit.io/c8xfvkbcd/' +
                                                              this.state
                                                                  .designInspiration
                                                                  .desktopViewImage
                                                            : 'https://ik.imagekit.io/c8xfvkbcd/DesignInspiration/' +
                                                              this.state
                                                                  .designInspiration
                                                                  .desktopViewImage
                                                    }
                                                    className="tansformed-img tw-w-full"
                                                    alt={
                                                        this.state
                                                            .designInspiration
                                                            .Productname +
                                                        ' website'
                                                    }
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </section>
                </div>
            </React.Fragment>
        );
    }
}
