import React, { Component } from 'react'
import parse from 'html-react-parser';

import Headers from 'components/SEO/Headers';
import GeneralFooter from 'components/Navbars/GeneralFooter';
import codeHelper from 'utils/codeHelper';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';
import languageENUMS from 'data/ENUMS/languageENUMS'
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import ConverterInterface from 'data/converter/ConverterInterface'

import Editor from './components/Editor'
import './CodeConverter.css'
import functions from './functions';
import Navbar from 'components/Navbars/Navbar';


interface state {
    inputCode: string;
    outputcode: string;
    tailwindOutputArray: any[],
    pageSEO: pageSEOInterface,
    convertor: ConverterInterface
}


export default class CodeConverter extends Component<any, state> {
    constructor(props) {
        super(props);

        this.convertSasstoCSS = this.convertSasstoCSS.bind(this);
        this.convertHtmltoJsx = this.convertHtmltoJsx.bind(this);
        this.convertHtmlToPug = this.convertHtmlToPug.bind(this);
        this.onChangeEditor = this.onChangeEditor.bind(this);
        this.jsonFormatter = this.jsonFormatter.bind(this);
        this.convertCSStoTailwind = this.convertCSStoTailwind.bind(this);
        this.state = {
            inputCode: '',
            outputcode: '',
            pageSEO: {} as pageSEOInterface,
            convertor: {} as ConverterInterface,
            tailwindOutputArray: []
        }
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {
        let tool = this.props.match.params.convertorTool;
        
        const codeConvertorHandler = new functions();
        const SEO = codeConvertorHandler.addSEO();
        const codeConvertordata = await codeConvertorHandler.getConvertorDetails(tool);
        
        SEO.settitle("Convert "+ codeConvertordata.from + " to " + codeConvertordata.to +" | CodeUI");
        SEO.setkeywords(codeConvertordata.tags);
        
        this.setState({
            pageSEO: SEO,
            convertor: codeConvertordata
        })
    }

    onChangeEditor(e) {
        this.setState({
            inputCode: e
        })
    }

    async convertSasstoCSS(e) {
        e.preventDefault()

        const CodeConverterHandler = new functions();
        const result = await CodeConverterHandler.convertSCSSTOCSS(this.state.inputCode);
        if (result.success) {
            const preetifyCode = await codeHelper.preetifyCode(result.convertedData, languageENUMS.CSS);
            this.setState({
                outputcode: preetifyCode
            })
        }
    }

    async jsonFormatter(e){
        e.preventDefault()

        const preetifyCode = await codeHelper.preetifyCode(this?.state?.inputCode, languageENUMS.JSONFORMATTER);
        await this.setState({
            outputcode: preetifyCode
        })
    }

    async convertHtmlToPug(e){
        e.preventDefault()

        const CodeConverterHandler = new functions();
        const result = await CodeConverterHandler.convertHTMLTOPUG(this.state.inputCode);
        if (result.success) {
            const preetifyCode = await codeHelper.preetifyCode(result.convertedData, languageENUMS.PUG);
            await this.setState({
                outputcode: preetifyCode
            })
        }

    }

    async convertHtmltoJsx(e){
        e.preventDefault();

        const CodeConverterHandler = new functions();
        const result = await CodeConverterHandler.convertHtmltoJsx(this.state.inputCode);
        if (result.success) {
            const preetifyCode = await codeHelper.preetifyCode(result.convertedData, languageENUMS.JSX);
            this.setState({
                outputcode: preetifyCode
            })
        }
    }

    async convertCSStoTailwind(e){
        e.preventDefault()

        const preetifyCode = await codeHelper.preetifyCode(this?.state?.inputCode, languageENUMS.TAILWIND);
 
        await this.setState({
            tailwindOutputArray: preetifyCode.data
        })
    }

    render() {
        return (
            <>
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="cc-container">
                    <div className="cc-converter-heading">
                        {this.state.convertor?.from} To {this.state.convertor?.to} Converter
                    </div>
                    <div className="cc-editor">
                        <div className="cc-io-editor">
                            <Editor
                                label={this.state.convertor?.from}
                                language={this.state.convertor?.from?.toLowerCase()}
                                theme='vs'
                                defaultContent={`/*Paste your ${this.state.convertor?.from} code here*/`}
                                onChange={(e) => this.onChangeEditor(e)}
                                value={this.state.inputCode}
                            />
                        </div>
                        <div className="cc-io-editor">
                            {
                                this.state.convertor.to === 'Tailwind' ?
                                <>
                                    <div className="cc-io-output">
                                        <div className='cc-io-output-label'>{this.state.convertor.to}</div>
                                        {
                                            this.state?.tailwindOutputArray.map((codeBlock) => (
                                                <div className="cc-tailwind">
                                                    <div className="cc-class">{codeBlock.selectorName}</div>
                                                    <div className="cc-style">
                                                        <div>{codeBlock.resultVal}</div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                                :
                                <Editor
                                    label={this.state.convertor?.to}
                                    language={this.state.convertor?.to}
                                    theme='vs'
                                    defaultContent='/*Output Code*/'
                                    onChange={() => {return}}
                                    value={this.state.outputcode}
                                />
                            }
                        </div>
                    </div>
                    <div className="cc-btn">
                        <button className='cc-convert-btn' onClick={this.convertCSStoTailwind}>Convert</button>
                    </div>
                    <div className="">
                        <div className="cc-des-wrapper">
                            <div className="cc-des-heading">
                                {/* {this.state.convertor?.converterName} */}
                            </div>
                            <div className="cc-des-text">
                                <div>
                                    {
                                        !stringUtilsImpl.isUndefinedEmptyorNull(this.state.convertor.description) ?
                                            parse(this.state.convertor.description)
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="cc-more-links">
                        <div className="cc-more-links-wrapper">
                            <div className="cc-more-links-heading">Other Links</div>
                            <div className="cc-links-box">
                                <div className="cc-links">
                                    <a href="url">HTML to JSX Converter</a>
                                    <a href="url">SCSS to CSS Converter</a>
                                    <a href="url">CSS to SCSS Converter</a>
                                    <a href="url">CSS to JSX/CSS Converter</a>
                                </div>
                                <div className="cc-links">
                                    <a href="url">JSON to XML Converter</a>
                                    <a href="url">Markdown to HTML Converter</a>
                                    <a href="url">CSV to JSON Converter</a>
                                    <a href="url">YAML to JSON Converter</a>
                                </div>
                                <div className="cc-links">
                                    <a href="url">Image to Base64 Converter</a>
                                    <a href="url">Temperature Converter</a>
                                    <a href="url">Currency Converter</a>
                                    <a href="url">Time Zone Converter</a>
                                </div>
                                <div className="cc-links">
                                    <a href="url">Binary to Decimal Converter</a>
                                    <a href="url">Length Converter</a>
                                    <a href="url">Volume Converter</a>
                                    <a href="url">Area Converter</a>
                                </div>

                            </div>
                        </div>
                    </div> */}
                </div>
                <GeneralFooter />
            </>
        )
    }
}
