import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";


export default class functions {

    async addLandingPage(dataJson) {
        const payload = {
            data: dataJson,
        }
        let result = await axiosCall(ApiRouteMapping.addLandingPage.apiPath, "POST", null, null, payload);

    }
}