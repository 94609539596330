// import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
// import './App.css';

import "./style.css";
import "./index.css";

import landingPage from 'pages/landingPage/landingPage';
import Login from 'pages/Login/Login';
import Designs from 'pages/designs/Designs';
import specificDesign from 'pages/specificDesign/specificDesign';
import Profile from 'pages/Profile/Profile';
import specificDesignFullWindow from 'pages/specificDesignFullWindow/specificDesignFullWindow';
import SubmitDesign from 'pages/SubmitDesign/SubmitDesign';
import LandingPageInspiration from 'pages/LandingPageInspiration/LandingPageInspiration';
import specificLandingPageInspiration from 'pages/specificLandingPageInspiration/specificLandingPageInspiration';
import codeGPTLandingPage from 'pages/codeGPTLandingPage/codeGPTLandingPage';
import Blogs from 'pages/Blogs/Blogs';
import AddBlog from 'pages/AddBlog/AddBlog';
import SpecificBlog from 'pages/SpecificBlog/SpecificBlog';
import Pricing from './pages/Pricing/Pricing';
import TwitterBioGenerator from 'pages/TwitterBioGenerator/TwitterBioGenerator';
import CodeConverter from 'pages/CodeConverter/CodeConverter';
import AddConverter from 'pages/AddConverter/AddConverter';
import AddLandingPage from 'pages/AddLandingPage/AddLandingPage';
import DesignBuilder from 'pages/DesignBuilder/DesignBuilder';
import NextjsSubmitDesign from 'pages/NextjsSubmitDesign/NextjsSubmitDesign';
import SpecificNextJsComponent from 'pages/SpecificNextJsComponent/SpecificNextJsComponent';
import NextjsComponent from 'pages/NextJsComponent/NextJsComponent';
import DocsNextjsComponent from 'pages/DocsNextjs/DocsNextjsComponent';
import Templates from 'pages/Templates/Templates';
import SpecificTemplates from 'pages/SpecificTemplates/SpecificTemplates';

function App() {
  return (
    <>
        <Router>
            <Switch>
              <Route path="/templates/:templateId" component={SpecificTemplates} />
              <Route path="/templates" component={Templates} />
              <Route path="/design-builder" component={DesignBuilder} />
              <Route path="/add-converter" component={AddConverter} />
              <Route path="/code-converter/:convertorTool" component={CodeConverter} />
              <Route path="/twitter-bio-generator" component={TwitterBioGenerator} />
              <Route path="/codeGPT" component={codeGPTLandingPage} />
              <Route path="/profile/:userId" component={Profile} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/login" component={Login} />
              <Route path="/inspiration-item-details/:inspirationId" component={specificLandingPageInspiration} />
              <Route path="/landing-page-inspiration" component={LandingPageInspiration} />
              <Route path="/add-landing-page" component={AddLandingPage} />
              {/* <Route path="/design-agency" component={serviceLandingPage} /> */}
              <Route path="/nextjs-component" exact component={NextjsComponent} />
              <Route path="/nextjs-component/:componentId" component={SpecificNextJsComponent} />
              <Route path="/docs/:componentId" component={DocsNextjsComponent} />
              <Route path="/nextjs-submit-design" component={NextjsSubmitDesign} />              
              <Route path="/designs" component={Designs} />
              <Route path="/submit-design" component={SubmitDesign} />
              <Route path="/show-full-design/:designId" component={specificDesignFullWindow} />
              <Route path="/design-code/:designId" component={specificDesign} />
              <Route path="/blogs" component={Blogs} />
              <Route path="/add-blog" component={AddBlog} />
              <Route path="/blog-detail/:blogId" component={SpecificBlog} />
              <Route path="/" component={landingPage} />
            </Switch>
        </Router>
    </>
  );
}

export default App;
