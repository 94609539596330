import React, { Component } from 'react';

import functions from './functions';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import designInspirationInterface from 'data/designInspiration/designInpirationForm/designInspirationInterface';
import designInspirationImpl from 'data/designInspiration/designInpirationForm/designInspirationImpl';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import LandingPageInspirationSection from './components/LandingPageInspirationSection';
import H1Hide from 'components/SEO/H1Hide';
import PrimaryLink from 'components/Button/PrimaryLink';
import Navbar from 'components/Navbars/Navbar';

export default class LandingPageInspiration extends Component<
    any,
    { pageSEO: pageSEOInterface; designInspiration: designInspirationInterface }
> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.getDesignInspirations = this.getDesignInspirations.bind(this);

        this.state = {
            pageSEO: {} as pageSEOInterface,
            designInspiration: {} as designInspirationInterface,
        };
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {
        const landingPageInspirationHandler = new functions();

        const SEO = landingPageInspirationHandler.addSEO();
        this.setState({ pageSEO: SEO });

        const designInspirationForm = new designInspirationImpl();
        await this.getDesignInspirations(designInspirationForm);
    }

    async getDesignInspirations(designInspirationForm) {
        const landingPageInspirationHandler = new functions();
        await landingPageInspirationHandler.getDesignInspirations(
            designInspirationForm,
        );
        this.setState({
            designInspiration: designInspirationForm,
        });
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />

                <div className="sb-hero tw-flex tw-pt-[120px] tw-items-center tw-pb-[145px]">
                    <div className="tw-flex tw-flex-col tw-w-5/6 lg:tw-w-6/12 tw-mx-auto">
                    <div className="tw-mt-[15px] tw-flex tw-flex-col tw-items-center tw-justify-center">
                            <h1 className="tw-m-0 tw-p-0 tw-text-light tw-text-center tw-text-[35px] tw-leading-tight md:tw-text-[58px] tw-font-bold tw-text-black-light inter">
                                The best landing page <br />
                                design inspiration
                            </h1>
                            <p className="tw-mx-auto tw-mt-[20px] md:tw-w-2/3 tw-m-0 tw-p-0 tw-text-light/80 tw-text-center tw-leading-normal tw-text-base md:tw-text-[22px] tw-font-normal ">
                                CodeUI Features the best landing page designs
                                that <br />
                                are available on the internet.
                            </p>
                        </div>
                        <div className="tw-flex tw-flex-col  md:tw-flex-row tw-items-center tw-justify-center tw-gap-4 md:tw-gap-2.5  tw-mt-6">
                            <PrimaryLink href="https://tally.so/r/wdYr1q" target="_blank">
                                Submit your landing page
                            </PrimaryLink>
                        </div>
                        {/* <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-gap-2.5 md:tw-gap-[12px] tw-mt-[25px]">
                            <div className="">
                                <img
                                    className="object-cover"
                                    src="https://ik.imagekit.io/codeui/CODE%20UI/Frame%2035778.svg?updatedAt=1716027701808"
                                    alt=""
                                />
                            </div>
                            <div className="tw-flex tw-flex-col ">
                                <img
                                    src="https://ik.imagekit.io/codeui/CODE%20UI/Frame%2035788.svg?updatedAt=1716027700072"
                                    className="tw-h-[15px] md:tw-w-1/2 "
                                    alt=""
                                />
                                <p className="tw-p-0 tw-m-0 tw-text-sm tw-font-medium tt-hoves-pro">
                                    Loved by Founders Worldwide
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <section>
                    <div className="tw-grid-responsive tw-grid tw-container tw-grid-cols-4 tw-mx-auto">
                        {!jsonUtilsImpl.isEmpty(this.state.designInspiration) &&
                        this.state.designInspiration.designInspirations.length >
                            0 ? (
                            <>
                                <LandingPageInspirationSection
                                designInspiration={
                                        this.state.designInspiration
                                    }
                                    getDesignInspirations={this.getDesignInspirations(
                                        this.state.designInspiration,
                                    )}
                                />
                            </>
                        ) : (
                            <>No Inspirations Available</>
                        )}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
