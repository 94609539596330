import minUserFormInterface from './minUserFormInterface'

export default class minUserFormImpl implements minUserFormInterface {
    Id: string;
    firstname: string;
    lastname: string;
    image: string;

    getId() {
        return this.Id;
    }

    getfirstname() {
        return this.firstname;
    }

    getlastname() {
        return this.lastname;
    }

    getimage() {
        return this.image;
    }

    initDatafromObject(data) {
        this.Id = data.Id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.image = data.image;
    }
}