import React, { Component } from 'react';

import Headers from '../../components/SEO/Headers';
// import functions from './functions';

import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';

import CodeSteps from 'components/CodeSteps/CodeSteps';
import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import SidebarItem from 'components/SidebarItem/SidebarItem';
import nextInstallationData from 'utils/nextInstallationDataJson';
import functions from './function';

interface DesignList {
    Id: string;
    title: string;
    description: string;
    imageURL: string;
    steps: string;
}
export default class DocsNextjsComponent extends Component<
    {},
    {
        pageSEO: pageSEOInterface;
        designs: DesignList[];
        nextjsComponent: any;
        nextjsInstallation: any;
    }
> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);

        this.state = {
            pageSEO: {} as pageSEOInterface,
            designs: [] as any,
            nextjsComponent: {} as any,
            nextjsInstallation: {} as any,
        };
    }

    componentWillMount() {
        this.init();
    }

    async init() {
        const installationHandler = new functions();
        const SEO = installationHandler.addSEO();
        this.setState({ pageSEO: SEO });
        this.setComponent();
    }
    async setComponent() {
        // @ts-ignore
        let componentId = this.props.match.params.componentId;
        const installationData = nextInstallationData.find((item) => {
            if (item.path === componentId) {
                return item;
            }
            return null;
        });
        if (installationData) {
            await this.setState({
                nextjsInstallation: installationData,
            });
        }

        const installationHandler = new functions();
        const installationResult =
            await installationHandler.getNextjsInstallation();

        this.setState({
            designs: installationResult,
        });
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="nextjs-Container tw-container">
                    <div className="nextjs-navContainer webkit-scroll">
                        <div className="nextjsnav">
                            <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                                <span className="black-text font-weight-bold">
                                    Follow For Updates
                                </span>
                                <div className="d-flex flex-col">
                                    <SidebarItem text=" Twitter @uday" />
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                                <span className="black-text font-weight-bold filter-heading">
                                    Installation
                                </span>
                                {nextInstallationData.map((items, i) => (
                                    <SidebarItem
                                        key={i}
                                        text={items.title}
                                        link={`/docs/${items.path}`}
                                    />
                                ))}
                            </div>
                            <div className="tw-flex tw-flex-col tw-p-10 tw-gap-2">
                                <span className="black-text font-weight-bold filter-heading">
                                    All Components
                                </span>
                                {this.state.designs.map((items, i) => (
                                    <SidebarItem
                                        key={i}
                                        text={items.title}
                                        link={`/nextjs-component/${items.Id}`}
                                    />
                                ))}
                            </div>
                        </div>
                        {/* component */}
                    </div>
                    {/* component */}
                    <div className="nextjs-designsContainer webkit-scroll">
                        <div className="flex flex-col">
                            <h3 className="margin-left-0 margin-right-0 font-bold">
                                {this.state.nextjsInstallation.title}
                            </h3>
                            <p className="margin-left-0 light-gray-text margin-right-0">
                                {this.state.nextjsInstallation.description}
                            </p>
                        </div>
                        <div className="padding-top-30">
                            <h4 className="margin-left-0 margin-right-0 font-bold">
                                Installation
                            </h4>
                            <hr />
                            <div className="steps-container">
                                {this.state.nextjsInstallation.steps.map(
                                    (items, i) => (
                                        <CodeSteps
                                            key={i}
                                            title={items.title}
                                            desc={items.description}
                                            code={items.code}
                                        />
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
