const templates = [
    {
        id: '22703fe0-2e81-51f1-8988-df0f16595bed',
        title: 'LandingPage',
        description:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid, architecto? Suscipit dolores alias mollitia qui obcaecati repellendus vero accusantium voluptatum',
        imageURL:
            'https://ik.imagekit.io/codeui/CODE%20UI/landing?updatedAt=1719053988658',
        designImage:
            'https://ik.imagekit.io/codeui/CODE%20UI/landing?updatedAt=1719053988658',
        features: [
            'best landing pages',
            'best landing page',
            'best landing page',
            'best landing page',
        ],
    },
    {
        id: '150156e0-7b4e-4ebc-bcc8-24e206e57b26',
        title: 'LandingPage 2',
        description:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid, architecto? Suscipit dolores alias mollitia qui obcaecati repellendus vero accusantium voluptatum',
        designImage:
            'https://ik.imagekit.io/codeui/CODE%20UI/b0f5232d-b499-4087-b4b0-89e409ff1630.png?updatedAt=1721113072903',
        features: [
            'best landing pages',
            'best landing page',
            'best landing page',
            'best landing page',
        ],
    },
    {
        id: '150156e0-7c4e-4ebc-bcc8-24e206e57b28',
        title: 'LandingPage 3',
        description:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid, architecto? Suscipit dolores alias mollitia qui obcaecati repellendus vero accusantium voluptatum',
        designImage:
            'https://ik.imagekit.io/codeui/CODE%20UI/e635a07f-53f6-4438-b023-3e2d88ce1fa0.png?updatedAt=1721042292739',
        features: [
            'best landing pages',
            'best landing page',
            'best landing page',
            'best landing page',
        ],
    },
    {
        id: '180156e0-7c4e-4ebc-bcc8-24e206s57b28',
        title: 'LandingPage 4',
        description:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid, architecto? Suscipit dolores alias mollitia qui obcaecati repellendus vero accusantium voluptatum',
        designImage:
            'https://ik.imagekit.io/codeui/CODE%20UI/bcf5b57d-854f-4464-a9f5-ef56e0272a1b.png?updatedAt=1721112697437',
        features: [
            'best landing pages',
            'best landing page',
            'best landing page',
            'best landing page',
        ],
    },
];

export { templates };
