import React, { Component } from 'react';
import CopyButton from 'components/Button/CopyButton';
import CodeBlock from 'components/CodeBlock/CodeBlock';

export default class HighlightCode extends Component<
    any,
    {
        code: String;
        className?: String;
    }
> {
    render() {
        return (
            <React.Fragment>
                <div className="relative">
                    <CodeBlock language="javascript" code={this.props.code} />
                    <CopyButton input={this.props.code} />
                </div>
            </React.Fragment>
        );
    }
}
