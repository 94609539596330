import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';
import React, { Component } from 'react'
import functions from './functions';
import Headers from 'components/SEO/Headers';
import IframewithWebandMobileView from './component/IframewithWebandMobileView';
import SmallFrame from 'components/Frame/SmallFrame';
import H1Hide from 'components/SEO/H1Hide';

export default class specificDesignFullWindow extends Component<any, { pageSEO: pageSEOInterface, design: designDetailsFormInterface}> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
            design: {} as designDetailsFormInterface
        }
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        const specificDesignHandler = new functions();
        let designId = this.props.match.params.designId

        const SEO = specificDesignHandler.addSEO();
        const designJson = await specificDesignHandler.getDesignsbyId(designId);
        
        SEO.settitle(designJson.name);
        SEO.setdescription(designJson.tagline);
        SEO.setkeywords(designJson.tags);
        SEO.setimage(designJson.imageLink);

        this.setState({
            pageSEO: SEO,
            design: designJson
        })
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                
                <IframewithWebandMobileView 
                    design={this.state.design}
                >
                    <SmallFrame
                        className="rounded-lg overflow-hidden border-none"
                        design={this.state.design}
                        height="100%"
                    />
                </IframewithWebandMobileView>

            </React.Fragment>
        )
    }
}
