import React, { Component } from 'react'

import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface'

export default class DesignCard extends Component<{design: minDesignFormInterface}> {
  render() {
    return (
        <a href={"/design-code/"+this.props.design.Id} className="Link">
            <div className="Card shadow-small shadow-onHover-medium">
                <div className="Card-body padding-0">
                    {
                        this.props.design.isPremium ?
                        <></>:
                        <div className="card-body-img-container">
                            <div className="onImage-tag">
                                <div className="bg-green-500 p-1 rounded-md flex items-center justify-center uppercase font-bold text-white text-xs px-2">
                                    Free
                                </div>
                            </div>
                        </div>
                    }
                    
                    <div className="CardImg-container">
                        {/* <img src={"https://ik.imagekit.io/c8xfvkbcd/tr:q-75/" + this.props.design.imageLink} */}
                        <img src={"https://ik.imagekit.io/c8xfvkbcd/tr:q-75/" + this.props.design.imageLink}
                            className='design-Image'
                            alt={this.props.design.name }
                        />
                    </div>
                </div>
                <div className="Card-footer">
                    <div className="d-flex space-between items-center">
                        <div className='d-flex items-center'>
                            <img src="https://lh3.googleusercontent.com/a/AGNmyxZiBYBt-ZTPdDuxDQCGHKKns2jBdGyBtH_n98sq=s96-c" alt={this.props.design.postedBy.firstname + " " + this.props.design.postedBy.lastname} width="30px" height="30px" className='roundedImg'/>
                            <p className="margin-0 margin-left-10">{this.props.design.name}</p>
                        </div>
                        <div className='d-flex items-center Form-Size-xs-Small'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path></svg>
                            <p className="margin-0 margin-left-5 Form-Size-xs-Small">{this.props.design.views}</p>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
  }
}
