import React, { Component } from 'react'
import Editor from '@monaco-editor/react'

interface props{
  language: string,
  theme: string,
  defaultContent: string,
  label: string,
  onChange: any,
  value: any,
}


export default class InputEditor extends Component<props> {

  render() {
    return (
        <>
          <label className='margin-left-0' style={{margin: '5px 20px'}}>{this.props.label}</label>
            <div className="Form-Code-Editor">
              <Editor
                height={'600px'}
                width={`100%`}
                language={this.props.language}
                theme={this.props.theme}
                defaultValue={this.props.defaultContent}
                onChange={this.props.onChange}
                options={{ automaticLayout: true,formatOnPaste: true}}
                value={this.props.value}
              />
            </div>
        </>
    )
  }
}
