import PrimaryLink from 'components/Button/PrimaryLink';
import DesignCard from 'components/Card/DesignCard';
import designFormInterface from 'data/design/designForm/designFormInterface';
import minDesignFormInterface from 'data/design/minDesignForm/minDesignFormInterface';
import React, { Component } from 'react';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import functions from '../functions';


export default class ServicesGrid extends Component<
    {},
    { designs: designFormInterface<minDesignFormInterface>; isLoading: boolean }
> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.setDesign = this.setDesign.bind(this);

        this.state = {
            designs: {} as designFormInterface<minDesignFormInterface>,
            isLoading: true as boolean,
        };
    }

    componentWillMount() {
        this.init();
    }
    async init() {
        this.setDesign();
    }

    async setDesign() {
        const designHandler = new functions();
        const designsResult = await designHandler.getDesigns();
        this.setState({
            designs: designsResult,
        });
        await this.toogleisLoading();
    }

    toogleisLoading() {
        this.setState((prevState) => ({
            isLoading: !prevState.isLoading,
        }));
    }
    render() {
        return (
            <>
                <div className="tw-bg-blue-light tw-py-10 buildfast-navbar-container ">
                    <div className='w-grid tw-grid-cols-4 tw-gap-4 tw-max-w-[85%] tw-mx-auto'>
                        <h2 className="tw-text-black tw-text-center tw-text-4xl md:tw-text-5xl tw-m-0 tw-p-0 tw-tracking-[-1.98%] tw-my-10">
                            Components
                        </h2>
                    </div>
                    <div className="tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-container tw-mx-auto">
                        {this.state.isLoading ? (
                            <div className="tw-col-span-2">
                                <p className="text-danger">Loading...</p>
                            </div>
                        ) : !jsonUtilsImpl.isEmpty(this.state.designs) &&
                          !jsonUtilsImpl.isEmpty(this.state.designs.designs) ? (
                            this.state.designs.designs.map((design) => {
                                return (
                                    <DesignCard
                                        design={design}
                                    />
                                )
                            })
                        ) : (
                            <div className="tw-col-span-2">
                                <p className="tw-text-black-light tw-text-center">
                                    No Designs Available
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2.5 md:tw-gap-4 tw-mt-[25px]">
                        <PrimaryLink href="/designs"> View More -&gt;</PrimaryLink>
                    </div>
                </div>
            </>
        );
    }
}
