import pageSEOImpl from 'data/SEO/pageSEO/pageSEOImpl';

import PageSEOData from 'utils/AllPageSEOData';
import ApiRouteMapping from 'utils/AllApiRouteMapping';
import axiosCall from 'lib/axios';
import designDetailsFormImpl from 'data/design/designDetailsForm/designDetailsFormImpl';
import helperFunctionsImpl from 'utils/helperFunctionsUtils/helperFunctionsImpl';
import userFormImpl from 'data/user/userForm/userFormImpl';

class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.specificDesigns;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getDesignsbyId(Id) {
        let params = {designId: Id}
        let result = await axiosCall(ApiRouteMapping.getDesignbyId.apiPath, "GET", params);
        const design = new designDetailsFormImpl();
        design.initfromDataObj(result.data.design);
        return design;
    }

    async getUserProfile() {
        let result = await axiosCall(ApiRouteMapping.getUser.apiPath, "GET");
        const user = new userFormImpl();
        user.initFromDataObject(result.data.user);
        return user;
    }
}

export default functions;