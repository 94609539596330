import PrimaryLink from 'components/Button/PrimaryLink';
import React from 'react';
import { FaCheck } from 'react-icons/fa6';

type PricingCardProps = {
    title: string;
    price: string;
    description: string;
    features: string[];
    buttonText: string;
    buttonLink: string;
    buttonOnClick: any
};
export default function PricingCard({
    title,
    price,
    description,
    buttonText,
    buttonLink,
    features,
    buttonOnClick
}: PricingCardProps) {
    return (
        <div className="md:tw-py-[50px] tw-border-[0.5px] tw-border-[#9E9E9E] tw-border-solid tw-py-[25px] tw-px-5 md:tw-px-[72px] tw-shadow-2xl tw-w-full tw-rounded-[10px] tw-mt-[60px] tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-justify-center"
        >
            <h2 className="tw-m-0 tw-p-0 tw-text-[36px] tw-text-black">{title}</h2>
            <p className="tw-m-0 tw-p-0 tw-text-[48px] tw-text-black/70 tw-my-[10px] tw-leading-[60.48px] tw-racking-[-1.98%] tw-font-semibold">
                {price}
            </p>
            <p className="tw-m-0 tw-p-0 tw-text-center tw-text-sm tw-text-grey tw-font-normal">
               {description}
            </p>

            <ul className="tw-flex tw-flex-col tw-gap-4 tw-mt-[45px] tw-mb-[30px]">
                {features.map((benefit) => (
                    <li
                        className="tw-flex tw-gap-2 tw-items-center tw-m-0 tw-p-0 tw-text-base tw-text-grey tw-font-normal"
                        key={benefit}
                    >
                        <FaCheck /> {benefit}
                    </li>
                ))}
            </ul>

            <PrimaryLink href={buttonLink} onClick={buttonOnClick}>{buttonText}</PrimaryLink>
        </div>
    );
}
