import React, { Component } from 'react';

export default class Input extends Component<any, any> {
    render() {
        return (
            <div className="Form-control margin-bottom-20">
                <label className="margin-left-0">{this.props.label}</label>
                {this.props.children === undefined ? (
                    <div className="Form-input">
                        <input
                            type={this.props.type}
                            value={this.props.value}
                            name={this.props.name}
                            onChange={this.props.onChange}
                            placeholder={this.props.placeholder}
                            required={this.props.required}
                        />
                    </div>
                ) : (
                    <>{this.props.children}</>
                )}
            </div>
        );
    }
}
