import React, { Component } from 'react'

export default class 
SectionWiseInput extends Component {
  render() {
    return (
      <div>
        <div className="Row">
            <div className="Column-4 InputSectionDetails">
                <h6 className="margin-left-0">{this.props.sectionHeading}</h6>
                <p className="margin-left-0">
                    {this.props.sectionDescription}
                </p>
            </div>
            <div className="Column-8">
                {this.props.children}
            </div>
        </div>
      </div>
    )
  }
}
