import React, { Component } from 'react';

import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import Headers from 'components/SEO/Headers';
import blogDetailFormInterface from 'data/blog/blogDetailForm/blogDetailFormInterface';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import BlogContent from './components/BlogContent';
import functions from './functions';



export default class SpecificBlog extends Component<any, { pageSEO: pageSEOInterface, blog: blogDetailFormInterface}>  {
    constructor(props) {
        super(props);
        
        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
            blog: {} as blogDetailFormInterface
        }
    }    

    componentDidMount(): void {
        this.init(); 
    }

    async init() {
        const specificBlogHandler = new functions();
        let blogId = this.props.match.params.blogId

        const SEO = specificBlogHandler.addSEO();

        const blogResult = await specificBlogHandler.getBlogbyId(blogId);
        
        SEO.settitle(blogResult.title);
        SEO.setimage(blogResult.image);
        SEO.setkeywords(blogResult.tags)
        
        this.setState({
            pageSEO: SEO,
            blog: blogResult
        })

        // const URL = `${this.props.match.url}/${blogResult.title}`;
        // this.props.history.push({ pathname: URL });
    }
    
    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <section>
                    <div className="desk-container bg-white">
                        <BlogContent 
                            blog = {this.state.blog}
                        />
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
