import React, { Component } from 'react'
import { isMobile } from 'react-device-detect';
import $ from "jquery";

import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';

export default class IframewithWebandMobileView extends Component<{design: designDetailsFormInterface, children: any}, {}> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this); 
        this.handleViewport = this.handleViewport.bind(this);
        this.state = {}
    }

    componentDidMount(): void {
        $("#mainnav").hide();
    }

    handleViewport(e) {
        let type = e.currentTarget.getAttribute('data-buttontype');
        if (type === 'desktop') {
            if (!$("#desktopViewport").hasClass("active")) {
                $("#desktopViewport").addClass("active");
                $("#mobileViewport").removeClass("active");
                $("#frame-container").width("100%");
            }
        } else {
            if (!$("#mobileViewport").hasClass("active")) {
                $("#mobileViewport").addClass("active");
                $("#desktopViewport").removeClass("active");
                $("#frame-container").width("30%");
            }
        }
    }

    init() {

    }

    render() {
        return (
            <div className="fixed top-0 left-0 min-h-screen max-h-screen w-full z-50 flex">
                <div className="z-50 relative p-4 w-full">

                    <div className="card">
                        <div className="card-body p-3 pt-2 pb-2">
                            <div className="flex justify-between items-center">
                                <div>
                                    <h6>{this.props.design.name}</h6>
                                </div>
                                <div>
                                    <div className="flex svg-size items-center">
                                        {!isMobile ?
                                            <div>
                                                <button className="viewport-button active" data-buttontype="desktop" id="desktopViewport" onClick={this.handleViewport}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192"
                                                        fill="#000000" viewBox="0 0 256 256">
                                                        <rect width="256" height="256" fill="none"></rect>
                                                        <rect x="32" y="48" width="192" height="144" rx="16"
                                                            transform="translate(256 240) rotate(180)" fill="none"
                                                            stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="16"></rect>
                                                        <line x1="160" y1="224" x2="96" y2="224" fill="none" stroke="#000000"
                                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="16">
                                                        </line>
                                                        <line x1="32" y1="152" x2="224" y2="152" fill="none" stroke="#000000"
                                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="16">
                                                        </line>
                                                        <line x1="128" y1="192" x2="128" y2="224" fill="none" stroke="#000000"
                                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="16">
                                                        </line>
                                                    </svg>
                                                </button>
                                            </div>
                                            :
                                            <></>
                                        }
                                        <div>
                                            <button className={isMobile ? "active viewport-button" : "viewport-button"} data-buttontype="mobile" id="mobileViewport" onClick={this.handleViewport}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192"
                                                    fill="#000000" viewBox="0 0 256 256">
                                                    <rect width="256" height="256" fill="none"></rect>
                                                    <rect x="24" y="64" width="208" height="128" rx="16"
                                                        transform="translate(256) rotate(90)" fill="none" stroke="#000000"
                                                        stroke-linecap="round" stroke-linejoin="round" stroke-width="16">
                                                    </rect>
                                                    <circle cx="128" cy="60" r="12"></circle>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="mt-3 overflow-hidden bg-gray-100 rounded-2xl display-box">
                        <div className="frame-container z-30 relative preview-box bg-white rounded-lg overflow-hidden mx-auto" id="frame-container" style={{ width: "100%" }}>
                            {this.props.children}
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}
