import axiosCall from 'lib/axios';
import ApiRouteMapping from 'utils/AllApiRouteMapping';

const functions = {
    createCheckout: async () => {
        let result = await axiosCall(
            ApiRouteMapping.getCheckoutUrl.apiPath,
            'GET',
        );
        return result.data.url;
    },
}

export default functions;
