import React, { Component } from 'react';

import SectionWiseInput from 'components/Form/SectionWiseInput';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import functions from './functions';
// import UploadImageInput from 'components/InputGroup/UploadImageInput';
import Button from 'components/Button/Button';
import EditorComponent from 'components/Editor/EditorComponent';
import Input from 'components/InputGroup/Input';
import Navbar from 'components/Navbars/Navbar';
import nextjscomponentDetailsFormInterface from 'data/nextjsComponent/nextjsComponentDetailsForm/nextjscomponentDetailsFormInterface';
import ReactSelectInterface from 'data/ReactSelect/ReactSelectInterface';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

export default class NextjsSubmitDesign extends Component<
    any,
    {
        designVariant: string;
        pageSEO: pageSEOInterface;
        design: nextjscomponentDetailsFormInterface;
        categories: ReactSelectInterface[];
        types: ReactSelectInterface[];
        iscategoryOther: boolean;
        istypeOther: boolean;
    }
> {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);

        this.state = {
            designVariant: 'javascript',
            pageSEO: {} as pageSEOInterface,
            design: {} as nextjscomponentDetailsFormInterface,
            categories: [] as ReactSelectInterface[],
            types: [] as ReactSelectInterface[],
            iscategoryOther: false as boolean,
            istypeOther: false as boolean,
        };
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {
        const SubmitDesignHandler = new functions();

        const SEO = SubmitDesignHandler.addSEO();
        this.setState({ pageSEO: SEO });
    }
    showInputForOther(editorName, value) {
        let stateJson = {};

        if (editorName === 'category') {
            stateJson['iscategoryOther'] = value;
        }

        if (editorName === 'type') {
            stateJson['istypeOther'] = value;
        }

        this.setState(stateJson);
    }
    onChangeHandler(e, editorName) {
        let name = '';
        let value = '';

        if (editorName === 'category' || editorName === 'type') {
            name = editorName;
            value = e.value;

            if (value === 'Other') {
                // Showing Input Box
                this.showInputForOther(name, true);
            } else {
                // Hiiding Input Box
                this.showInputForOther(name, false);
            }
        } else if (!stringUtilsImpl.isUndefinedEmptyorNull(editorName)) {
            name = editorName;
            value = e;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        this.setState((prevState) => ({
            design: {
                ...prevState.design,
                [name]: value,
            },
        }));
    }

    async onSubmit(e) {
        e.preventDefault();
        const AddPostHandler = new functions();
        await AddPostHandler.addPost(this.state.design);
    }

    render() {
        return (
            <React.Fragment>
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="desk-container">
                    <section>
                        <p className="margin-0">Next js Component <br /> Submit Design</p>
                        <hr />
                        <form onSubmit={this.onSubmit}>
                            <SectionWiseInput
                                sectionHeading="Next js Component"
                                sectionDescription="Do it"
                            >
                                <Input
                                    label="Title"
                                    name="title"
                                    onChange={this.onChangeHandler}
                                />
                                <Input
                                    label="Description"
                                    name="description"
                                    onChange={this.onChangeHandler}
                                />
                                <Input
                                    label="Image URL"
                                    name="imageURL"
                                    onChange={this.onChangeHandler}
                                />
                                <Input
                                    label="Hosted URL"
                                    name="hostedURL"
                                    onChange={this.onChangeHandler}
                                />
                            </SectionWiseInput>

                            <hr />
                            <SectionWiseInput
                                sectionHeading="Code"
                                sectionDescription="Do it"
                            >
                                    <EditorComponent
                                        label="Steps"
                                        language="javascript"
                                        onChange={(e) =>
                                            this.onChangeHandler(
                                                e,
                                                'steps',
                                            )
                                        }
                                        height="250px"
                                    />
                                    <EditorComponent
                                        label="CLI Steps"
                                        language="javascript"
                                        onChange={(e) =>
                                            this.onChangeHandler(
                                                e,
                                                'cliSteps',
                                            )
                                        }
                                        height="250px"
                                    />
                                    <EditorComponent
                                        label="Code Usage"
                                        language="javascript"
                                        onChange={(e) =>
                                            this.onChangeHandler(
                                                e,
                                                'codeUsage',
                                            )
                                        }
                                        height="250px"
                                    />
                            </SectionWiseInput>
                            <hr />
                            <SectionWiseInput
                                sectionHeading="Additional Information"
                                sectionDescription="Do it"
                            >
                                <Input
                                    label="Tags"
                                    name="tags"
                                    onChange={this.onChangeHandler}
                                />

                                <Input
                                    label="Creator Name"
                                    name="creator"
                                    onChange={this.onChangeHandler}
                                />

                                <Input
                                    label="Creator Profile"
                                    name="creatorProfile"
                                    onChange={this.onChangeHandler}
                                />

                                
                                <Input
                                    label="Dependencies"
                                    name="dependencies"
                                    onChange={this.onChangeHandler}
                                />

                                <Input
                                    label="DevDependencies"
                                    name="devDependencies"
                                    onChange={this.onChangeHandler}
                                />

                                <Button
                                    id={undefined}
                                    text="Submit Design"
                                    className="btn btn-large shadow-small btn-primary"
                                    type="submit"
                                />
                            </SectionWiseInput>
                        </form>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}
