import React, { Component } from 'react';

import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';
import functions from './functions';
import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import UserProfile from 'components/User/UserProfile';
import ButtonwithIcon from 'components/Button/ButtonwithIcon';
import SmallFrame from 'components/Frame/SmallFrame';
import { Tabs, Tab, TabList, TabPanel } from 'components/Tabs/Tabs';
import EditorComponent from 'components/Editor/EditorComponent';
import jsonUtilsImpl from 'utils/jsonUtils/jsonUtilsImpl';
import H1Hide from 'components/SEO/H1Hide';
import Navbar from 'components/Navbars/Navbar';
import AdvertiseComponentsProduct from 'components/AdvertiseComponentsProduct';
import userFormInterface from 'data/user/userForm/userFormInterface';

export default class specificDesign extends Component<
    any,
    { pageSEO: pageSEOInterface; design: designDetailsFormInterface; user: userFormInterface }
> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = {
            pageSEO: {} as pageSEOInterface,
            design: {} as designDetailsFormInterface,
            user: {} as userFormInterface
        };
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        const specificDesignHandler = new functions();
        let designId = this.props.match.params.designId;
        const SEO = specificDesignHandler.addSEO();
        const designJson = await specificDesignHandler.getDesignsbyId(designId);
        SEO.settitle(designJson.name);
        SEO.setdescription(designJson.tagline);
        SEO.setkeywords(designJson.tags);
        SEO.setimage(designJson.imageLink);

        if(localStorage.getItem('token')) {
            const userProfile = await specificDesignHandler.getUserProfile();
            this.setState({
                user: userProfile
            });
        }
        

        this.setState({
            pageSEO: SEO,
            design: designJson
        });
    }

    render() {
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="desk-container">
                    <section>
                        <div className="d-flex items-center space-between">
                            <div>
                                {!jsonUtilsImpl.isEmpty(this.state.design) &&
                                !jsonUtilsImpl.isEmpty(
                                    this.state.design.postedBy,
                                ) ? (
                                    <UserProfile
                                        user={this.state.design.postedBy}
                                        size={70}
                                        name={this.state.design.name}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div>
                                <ButtonwithIcon
                                    text="Full Window"
                                    className="btn btn-large items-center shadow-small btn-with-icon-hidetext"
                                    designId={this.state.design.Id}
                                />
                            </div>
                        </div>

                        <div className="padding-top-30">
                            <SmallFrame
                                className=""
                                design={this.state.design}
                                height="400px"
                            />
                        </div>
                        {/* <div>{this.state.design.isTailwind === true ? "trueTailwind" : "falseTailwind" }</div>
                        <div>{this.state.design.isNextjs === true ? "trueNextjs" : "falseNextjs" }</div> */}
                        {
                            this.state.design.isPremium ?
                            
                                this.state.user?.subscriptionType === 'PREMIUM' || this.state.user?.subscriptionType === 'LIFETIME' ?

                                <div className="padding-top-30">
                                <Tabs selected={0}>
                                    <TabList tabName="TabList">
                                        <Tab tabName="Tab">
                                            {/* eslint-disable-next-line */}
                                            <a className="font-18">
                                                {
                                                    this.state.design.isTailwind === true ?
                                                        "HTML + Tailwind"
                                                        :
                                                        "HTML"
                                                }
                                            </a>
                                        </Tab>

                                        {
                                            this.state.design.isTailwind === true ?
                                                <></> :
                                                <Tab tabName="Tab">
                                                    {/* eslint-disable-next-line */}
                                                    <a className="font-18">CSS</a>
                                                </Tab>
                                        }

                                        <Tab tabName="Tab">
                                            {/* eslint-disable-next-line */}
                                            <a className="font-18">Javascript</a>
                                        </Tab>
                                    </TabList>
                                    <hr />

                                    <TabPanel tabName="TabPanel">
                                        <EditorComponent
                                            value={this.state.design.html}
                                            language="html"
                                            height="600px"
                                        />
                                    </TabPanel>

                                    <TabPanel tabName="TabPanel">
                                        <EditorComponent
                                            value={this.state.design.css}
                                            language="css"
                                            height="600px"
                                        />
                                    </TabPanel>
                                    <TabPanel tabName="TabPanel">
                                        <EditorComponent
                                            value={this.state.design.javascript}
                                            language="javascript"
                                            height="600px"
                                        />
                                    </TabPanel>
                                </Tabs>
                                </div>
                                :
                                <div className='margin-top-30'>
                                    <AdvertiseComponentsProduct
                                        heading="Want a code for this component?"
                                        description="Buy a lifetime subscription of the full platform"
                                        href="/designs"
                                        ctaText="Buy LifeTime Deal for $9"
                                    />
                                </div>
    
                            :
                            <div className="padding-top-30">
                                <Tabs selected={0}>
                                    <TabList tabName="TabList">
                                        <Tab tabName="Tab">
                                            {/* eslint-disable-next-line */}
                                            <a className="font-18">
                                                {
                                                    this.state.design.isTailwind === true ?
                                                        "HTML + Tailwind"
                                                        :
                                                        "HTML"
                                                }
                                            </a>
                                        </Tab>

                                        {
                                            this.state.design.isTailwind === true ?
                                                <></> :
                                                <Tab tabName="Tab">
                                                    {/* eslint-disable-next-line */}
                                                    <a className="font-18">CSS</a>
                                                </Tab>
                                        }

                                        <Tab tabName="Tab">
                                            {/* eslint-disable-next-line */}
                                            <a className="font-18">Javascript</a>
                                        </Tab>
                                    </TabList>
                                    <hr />

                                    <TabPanel tabName="TabPanel">
                                        <EditorComponent
                                            value={this.state.design.html}
                                            language="html"
                                            height="600px"
                                        />
                                    </TabPanel>

                                    <TabPanel tabName="TabPanel">
                                        <EditorComponent
                                            value={this.state.design.css}
                                            language="css"
                                            height="600px"
                                        />
                                    </TabPanel>
                                    <TabPanel tabName="TabPanel">
                                        <EditorComponent
                                            value={this.state.design.javascript}
                                            language="javascript"
                                            height="600px"
                                        />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        }
                    </section>
                </div>
            </React.Fragment>
        );
    }
}
