import Navbar from 'components/Navbars/Navbar';
import React from 'react';
import { TiStarburst } from 'react-icons/ti';
import { templates } from 'utils/templates';
import functions from './functions';

export default function SpecificTemplates(props) {
    let templateId = props.match.params.templateId;
    const template = templates.find(({ id }) => id === templateId);

    async function onSubmit() {
        const url = await functions.createCheckout();
        window.location.href = url;
    }

    return (
        <>
            <Navbar />
            <div className="specific-templates">
                <div className="specific-templates-container ">
                    <div className="template-design">
                        <div className="template-design-text">
                            <h2>{template.title}</h2>
                            <p>{template.description}</p>
                        </div>
                        <div className="template-design-image">
                            <img
                                src={template.designImage}
                                alt={template.title}
                            />
                        </div>
                    </div>
                    <div className="templates-design-sidebar">
                        <div className="template-pricing-card">
                            <span className="template-pricing-tag">
                                Love this Item?
                            </span>
                            <h3>
                                Enjoy all the benefits of an Codeui
                                subscription.
                            </h3>
                            <div className="template-pricing-features">
                                {template.features.map((feature) => (
                                    <p>
                                        <TiStarburst />
                                        {feature}
                                    </p>
                                ))}
                            </div>
                            <button onClick={onSubmit} className="template-btn">
                                Buy Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
