import React, { Component } from 'react';

class Tab extends Component<any, any> {
    render() {
        const { _onClick, _isActive, children } = this.props;
        return (
            <li
                className={`Tab ${_isActive ? 'is-active' : ''} list-tabs tw-cursor-pointer`}
                onClick={_onClick}
            >
                {children}
            </li>
        );
    }
}

class TabPanel extends Component<any, any> {
    render() {
        const { _isActive, children } = this.props;
        return (
            <div
                className={`TabPanel ${_isActive ? 'is-active' : ''}`}
            >
                {children}
            </div>
        );
    }
}

class TabList extends Component<any, any> {
    render() {
        const { children } = this.props;
        return <ul className="ul-list-tabs">{children}</ul>;
    }
}

class Tabs extends Component<
    { selected: number; children: React.ReactNode },
    any
> {
    constructor(props) {
        super(props);

        this.setSelected = this.setSelected.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.renderTabList = this.renderTabList.bind(this);
        this.renderChildren = this.renderChildren.bind(this);

        this.state = {
            selected: this.props.selected,
        };
    }

    setSelected(selected) {
        if (selected !== this.state.selected) {
            this.setState({ selected });
        }
    }

    handleClick(tab) {
        return () => this.setSelected(tab);
    }

    renderTabList(child) {
        let tab = 0;
        return React.cloneElement(child, {
            children: React.Children.map(child.props.children, (childTab) => {
                if (childTab.props.tabName === 'Tab') {
                    const _isActive = tab === this.state.selected;
                    const _onClick = this.handleClick(tab);
                    tab++;
                    return React.cloneElement(childTab, {
                        _isActive,
                        _onClick,
                    });
                }
                return childTab;
            }),
        });
    }

    renderChildren(children) {
        let panel = 0;

        return React.Children.map(children, (child) => {
            if (child.props.tabName === 'TabList') {
                return this.renderTabList(child);
            }

            if (child.props.tabName === 'TabPanel') {
                const _isActive = panel === this.state.selected;

                panel++;

                return React.cloneElement(child, { _isActive });
            }

            return child;
        });
    }

    render() {
        return (
            <div className="Tabs">
                {this.renderChildren(this.props.children)}
            </div>
        );
    }
}

export { TabList, TabPanel, Tab, Tabs };
