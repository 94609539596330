'use client';

import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

type CodeBlockProps = {
    language: string;
    code: string;
};

const CodeBlock: React.FC<CodeBlockProps> = ({ language, code }) => {
    return (
        <SyntaxHighlighter
            customStyle={{ backgroundColor: '#1a1a1a' }}
            language={language}
            style={dracula}
            wrapLongLines={true}
        >
            {code}
        </SyntaxHighlighter>
    );
};

export default CodeBlock;
