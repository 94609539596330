import React, { Component } from 'react';
import { FaEye } from 'react-icons/fa6';

export default class NextjsDesignCard extends Component<
    any,
    {
        text: string;
        title: String;
        description: String;
        image: String;
        path: String;
        views: number;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
            title: this.props.title,
            description: this.props.description,
            image: this.props.image,
            path: this.props.path,
            views: this.props.views,
        };
    }

    render() {
        return (
            <React.Fragment>
                <a
                    href={`/nextjs-component/${this.props.path}`}
                    className="nextjs-design shadow-small shadow-onHover-medium "
                >
                    <img
                        src={this.props.image}
                        alt={this.props.title}
                        className="aspect-ratio"
                    />
                    <div className="nextjs-footer">
                        <p className="nextjs-footer-title">
                            {this.props.title}
                        </p>
                        <div className="tw-flex tw-items-center tw-gap-2">
                            <FaEye className='tw-text-black' />
                            {this.props.views}
                        </div>
                    </div>
                </a>
            </React.Fragment>
        );
    }
}
