import designFiltersFormInterface from '../designFilters/designFiltersFormInterface';
import designFormInterface from './designFormInterface';


export default class designFormImpl<T> implements designFormInterface<T> {

    designs: T[] = [];
    filters: designFiltersFormInterface;
    
    public getDesigns() {
        return this.designs;
    }

    public initFromDataObject(data: T[], designConstructor: new () => T & { initDatafromObject(data: any): void }) {
        for(let i = 0; i < data.length; i++) {
            let designDetail = new designConstructor();
            designDetail.initDatafromObject(data[i]);
            this.designs.push(designDetail);
        }
    }
}