import React, { Component } from 'react'

export default class H1Hide extends Component<{content: string}, {}> {
  render() {
    return (
      <h1 style={{display: 'none'}}>
        {this.props.content}
      </h1>
    )
  }
}
