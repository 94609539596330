import 'index.css';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import './DesignBuilder.css';

import Navbar from 'components/Navbars/Navbar';
import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';
import grapesjs, { Editor } from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import functions from 'pages/designs/functions';
import specificDesignFunctions from 'pages/specificDesign/functions';
import DesignBuilderCard from './components/DesignBuilderCard';
import DesignBuilderFrame from './components/DesignBuilderFrame';
import DesignBuilderOverlay from './components/DesignBuilderOverlay';

const DesignBuilder = () => {
    const scripts = ['https://cdn.tailwindcss.com'];
    const [designs, setDesigns] = useState<designDetailsFormInterface[]>([]);
    const [createdDesign, setCreatedDesign] = useState<
        designDetailsFormInterface[]
    >([]);
    const [showCodeOverlay, setShowCodeOverlay] = useState<boolean>(false);
    const [grapesjsEditor, setGrapesjsEditor] = useState<Editor | null>(null);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const editor = grapesjs.init({
            container: '#gjs',
            height: '100%',
            width: '100%',
            plugins: ['gjs-preset-webpage'],
            storageManager: {
                type: 'local',
                autosave: false,
                autoload: false,
            },
            canvas: {
                styles: [
                    'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css',
                ],
                scripts: scripts,
            },
            panels: {
                defaults: [],
            },
        });
        setGrapesjsEditor(editor);

        return () => {
            editor.destroy();
        };
    }, []);
    

    const fetchData = async (filters = undefined) => {
        const designHandler = new functions();
        const designsResult: designDetailsFormInterface[] =
            await designHandler.getDesignsForBuilder(filters);
        setDesigns(designsResult);
    };

    const handleOnDragEnd = async (result: DropResult) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        let add;
        let active = [...designs];
        let complete = [...createdDesign];

        if (source.droppableId === 'editor-component') {
            add = active[source.index];
            active.splice(source.index, 1);
        } else {
            add = complete[source.index];
            complete.splice(source.index, 1);
        }

        const specificDesignsHandler = new specificDesignFunctions();
        add = await specificDesignsHandler.getDesignsbyId(add.Id);

        if (destination.droppableId === 'editor-component') {
            active.splice(destination.index, 0, add);
        } else {
            complete.splice(destination.index, 0, add);
        }

        setCreatedDesign(complete);
        setDesigns(active);
    };

    const getCreatedDesignCode = () => {
        setShowCodeOverlay(true);
    };

    return (
        <>
            {showCodeOverlay && (
                <DesignBuilderOverlay
                    showCodeOverlay={setShowCodeOverlay}
                    createdDesign={createdDesign}
                />
            )}
            <Navbar />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <div className="db-main-container">
                    <div className="db-nav">
                        <div className="db-nav-btn">
                            <div
                                className="text-3xl db-nav-btn-export"
                                onClick={getCreatedDesignCode}
                            >
                                Export
                            </div>
                        </div>
                    </div>
                    <div className="db-editor">
                        <div className="db-editor-left">
                            <Droppable droppableId="editor-component">
                                {(provided) => (
                                    <div
                                        className="db-editor-menu webkit-scroll"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        <div className="db-editor-menu-heading">
                                            Components
                                        </div>
                                        <div className="db-editor-components">
                                            {designs.map((design, index) => (
                                                <div
                                                    className="db-design-card-box"
                                                    key={design.Id}
                                                >
                                                    <DesignBuilderCard
                                                        design={design}
                                                        index={index}
                                                        isComponent={true}
                                                    />
                                                </div>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div className="db-editor-right">
                            <Droppable droppableId="editor-page">
                                {(provided) => (
                                    <div
                                        className="db-editor-page webkit-scroll"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {createdDesign.map((design, index) => (
                                            <div key={design.Id}>
                                                <DesignBuilderFrame
                                                    grapesjsEditor={
                                                        grapesjsEditor
                                                    }
                                                    createdDesign={design}
                                                    index={index}
                                                />
                                            </div>
                                        ))}
                                        <div id="gjs"></div>
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </div>
            </DragDropContext>
        </>
    );
};

export default DesignBuilder;
