const ApiRouteMapping = {
    getCheckoutUrl: {
        apiPath: '/payments/checkout',
    },
    getAllLandingPages: {
        apiPath: '/landingpage/getAllLandingPages',
    },
    getDesigns: {
        apiPath: '/design/',
    },

    getDesignsWithCategories: {
        apiPath: '/design/getcategoryspecific/',
    },

    getAllCategories: {
        apiPath: '/categories/',
    },

    getAllDesignType: {
        apiPath: '/design/helper/getType/',
    },

    getAllDesignTypeWithCategories: {
        apiPath: '/design/helper/getTypeWithCategories/',
    },

    getDesignsByArray: {
        apiPath: '/design/getLandingPageDesigns/',
    },

    getDesignbyId: {
        apiPath: '/design/particular/',
    },

    getDesignsbyUser: {
        apiPath: '/design/getdesignsbyuser',
    },

    getUser: {
        apiPath: '/user/getuser',
    },

    submitDesign: {
        apiPath: '/design/submit',
    },

    nextjsSubmitDesign: {
        apiPath: '/nextjscomponent/submit',
    },
    nextjsGetDesigns: {
        apiPath: '/nextjscomponent/',
    },

    nextjsGetSpecificDesign: {
        apiPath: '/particular/nextjscomponent',
        method: 'GET',
    },
    getDesignInspirations: {
        apiPath: '/design-inspiration/',
    },

    getDesignInspirationbyId: {
        apiPath: '/design-inspiration/get-specific/',
    },

    addLandingPage: {
        apiPath: '/design-inspiration/add-landing-page',
    },

    loginUser: {
        apiPath: '/user/',
    },

    addBlog: {
        apiPath: '/blog/add-blog',
    },

    getBlogs: {
        apiPath: '/blog',
    },

    getBlogbyId: {
        apiPath: '/blog/get-specific',
    },

    addFeedback: {
        apiPath: '/feedback',
    },

    addImage: {
        apiPath: '/image/add-image',
    },

    addConverter: {
        apiPath: '/converter/add-converter',
    },
    scssTOcss: {
        apiPath: '/converter/convert-sass-to-css',
        method: 'GET',
    },
    htmlTopug: {
        apiPath: '/converter/convert-html-to-pug',
        method: 'GET',
    },
    htmlToJsx: {
        apiPath: '/converter/convert-html-to-jsx',
        method: 'GET',
    },
    getConvertor: {
        apiPath: '/converter/get-convertor',
        method: 'GET',
    },
};

export default ApiRouteMapping;
