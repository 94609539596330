import designDetailsFormInterface from 'data/design/designDetailsForm/designDetailsFormInterface';
import { type Editor } from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import React, { useEffect } from 'react';

interface PropsType {
  createdDesign: designDetailsFormInterface;
  index: number;
  grapesjsEditor: Editor;
}

const DesignBuilderFrame: React.FC<PropsType> = ({
  createdDesign,
  index,
  grapesjsEditor,
}) => {
  useEffect(() => {
    let htmlCode = `<html>
              <body>${createdDesign.html}</body>
              <style>${createdDesign.css}</style>
              <script>${createdDesign.javascript}</script>
          </html>`;
    grapesjsEditor.addComponents(htmlCode, { at: index });
  }, [createdDesign, grapesjsEditor, index]);
  return <></>;
};

export default DesignBuilderFrame;