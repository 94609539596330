import Editor from '@monaco-editor/react'
import React, { Component } from 'react'

export default class EditorComponent extends Component<any, any> {
    render() {
        return (
            <div className="Form-control margin-bottom-20">
                <label className='margin-left-20'>{this.props.label}</label>
                <div className="Form-Code-Editor">
                    <Editor
                        height={this.props.height}
                        width={`100%`}
                        language={this.props.language}
                        theme="vs-dark"
                        defaultValue="some comment"
                        onChange={this.props.onChange}
                        value={this.props.value}
                    />
                </div>
            </div>
        )
    }
}
