import PrimaryLink from 'components/Button/PrimaryLink';
import React, { Component } from 'react';

export default class Hero extends Component<any, any> {
    render() {
        return (
            <div className="sb-hero tw-flex tw-pt-[120px] tw-items-center tw-pb-[145px]">
                <div className="tw-flex tw-flex-col tw-w-5/6 lg:tw-w-6/12 tw-mx-auto">
                    <button className="tw-text-blue tw-text-base tw-leading-[19.36px] tw-font-bold" onClick={(() => {window.location.href = "mailto:uday@buildfast.co.in"})}>
                        Request a component -&gt;
                    </button>
                    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 md:tw-gap-[20px] tw-mt-[30px]">
                        <div className="flex items-center">
                            <img
                                className="tw-h-[25px] tw-w-[25px]"
                                src="https://ik.imagekit.io/codeui/CODE%20UI/nextjs-svgrepo-com%201.svg?updatedAt=1715887186948"
                                alt=""
                            />
                            <p className="tw-text-grey tw-text-xs md:tw-text-sm tw-font-medium tw-m-0 tw-ml-2 tt-hoves-pro">
                                Build using Nextjs
                            </p>
                        </div>
                        <div className="tw-flex tw-items-center">
                            <img
                                className="tw-h-[25px] tw-w-[25px]"
                                src="https://ik.imagekit.io/codeui/CODE%20UI/tailwind-svgrepo-com%20(1)%201.svg?updatedAt=1715887186924"
                                alt=""
                            />
                            <p className="tw-text-grey tw-text-xs md:tw-text-sm tw-font-medium tw-m-0 tw-ml-2 tt-hoves-pro">
                                Build using tailwind
                            </p>
                        </div>
                        <div className="tw-flex tw-items-center">
                            <img
                                className="tw-h-[25px] tw-w-[25px]"
                                src="https://ik.imagekit.io/codeui/CODE%20UI/customize-svgrepo-com%201.svg?updatedAt=1715887187897"
                                alt=""
                            />
                            <p className="tw-text-grey tw-text-xs md:tw-text-sm tw-font-medium tw-m-0 tw-ml-2 tt-hoves-pro">
                                Highly customizable
                            </p>
                        </div>
                    </div>
                    <div className="tw-mt-[15px] tw-flex tw-flex-col tw-items-center tw-justify-center">
                        <h1 className="tw-m-0 tw-p-0 tw-text-light tw-text-center tw-text-4xl tw-leading-tight md:tw-text-[58px] tw-font-bold tw-text-black-light inter">
                            Component Library to build your product
                            faster
                        </h1>
                        <p className="tw-mx-auto tw-mt-[20px] md:tw-w-2/3 tw-m-0 tw-p-0 tw-text-light/80 tw-text-center tw-leading-normal tw-text-base md:tw-text-[22px] tw-font-normal">
                            Beautifully designed mobile responsive components
                            that your can directly copy paste into your
                            projects.
                        </p>
                    </div>
                    <div className="tw-flex tw-flex-col  md:tw-flex-row tw-items-center tw-justify-center tw-gap-4 md:tw-gap-2.5  tw-mt-6">
                        <PrimaryLink href="/designs">Web Components</PrimaryLink>
                        <PrimaryLink variant="outline" href="https://www.buildfast.co.in">
                            Hire a design agency
                        </PrimaryLink>
                    </div>
                </div>
            </div>
        );
    }
}
