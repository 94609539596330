import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


import Headers from 'components/SEO/Headers';
import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import functions from './functions';

import Footer from 'components/Navbars/Footer';
import Navbar from 'components/Navbars/Navbar';
import H1Hide from 'components/SEO/H1Hide';
import env from 'config';

export default class Login extends Component<any, {pageSEO: pageSEOInterface}> {
    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);

        this.state = {
            pageSEO: {} as pageSEOInterface,
        }
    }    

    componentDidMount(): void {
        this.init();
    }

    async init() {
        const LoginHandler = new functions();
        const SEO = LoginHandler.addSEO();
        this.setState({ pageSEO: SEO });
    }

    onSuccess(res) {
        this.loginUser(res);
    }

    async loginUser(res) {
        const LoginHandler = new functions();;
        const user = await LoginHandler.loginUser(res);
        if(user.success) {
            localStorage.setItem('token', user.user);
            window.location.reload();
        }
    }

    onFailure(res) {
        // console.log('[Login failed] res:', res);
    }

    render() {
        if (localStorage.getItem('token')) {
            return <Redirect to='/designs' />
        }
        return (
            <React.Fragment>
                <H1Hide content={this.state.pageSEO.title} />
                <Headers SEO={this.state.pageSEO} />
                <Navbar />
                <div className="flex-vertical-center-center padding-y-60 wf-section">
                    <div className="global-container sb-text-center">
                      <section className='welcome-Aboard-section'>
                        <div className='center'>
                            <div>
                              <h1>Welcome Aboard</h1>
                              <p>Join with other great developers, builders.</p>
                            </div>
                            <div className='google-login-button'>
                            <GoogleOAuthProvider clientId={env.googleLogin.clientid}>
                              <GoogleLogin
                                  onSuccess={this.onSuccess}
                                  onError={() => this.onFailure}
                                />  
                            </GoogleOAuthProvider>
                          </div>
                        </div>
                      </section>
                  </div>
              </div>
              <Footer />

            </React.Fragment>
        )
    }
}
