import minUserFormInterface from "data/user/minUserForm/minUserFormInterface";
import designDetailsFormInterface from "./designDetailsFormInterface";

export default class designDetailsFormImpl implements designDetailsFormInterface {
    Id: string;
    tagline: string;
    name: string;
    imageLink: string;
    tags: string;
    category: string;
    html: string;
    css: string;
    javascript: string;
    isTailwind:Boolean;
    isNextjs:Boolean;
    details: string;
    createdAt: string;
    updatedAt: string;
    views: string;
    type: string;
    isPremium: string;
    postedBy: minUserFormInterface;

    initfromDataObj(data) {
        this.Id = data.Id;
        this.tagline = data.tagline;
        this.name = data.name;
        this.imageLink = data.imageLink;
        this.tags = data.tags;
        this.category = data.category;
        this.html = data.html;
        this.css = data.css;
        this.javascript = data.javascript;
        this.isTailwind = data.isTailwind;
        this.isNextjs= data.isNextjs;
        this.details = data.details;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.views = data.views;
        this.type = data.type;
        this.isPremium = data.isPremium;
        this.postedBy = data.postedBy;
    }
}