import React, { Component } from 'react'
import parse from 'html-react-parser';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl'
import blogDetailFormInterface from 'data/blog/blogDetailForm/blogDetailFormInterface';
import dateUtilsImpl from 'utils/dateUtils/dateUtilsImpl';
import Prism from "prismjs";

// import 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-javascript';

export default class BlogContent extends Component<{ blog: blogDetailFormInterface }, {}> {

    componentDidMount(): void {
        setTimeout(() => Prism.highlightAll(), 0);
    }
    render() {
        return (
            <div className="buildfast-blog">
                <div className="Row">
                    <div className="Column-6 margin-auto">
                        <h2 className="text_40 sb-biofolio no-margin">{this.props.blog.title}</h2>
                        <hr />
                        <div>
                            Posted On: {dateUtilsImpl.formatDate(this.props.blog.createdAt)}
                        </div>
                        <hr />

                        <div className="margin-top-20">
                            {
                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.blog.content) ?
                                    parse(this.props.blog.content)
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
