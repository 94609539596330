export default class designCategoriesFormImpl {
    Id: string;
    name: string;

    getId() {
        return this.Id;
    }

    getname() {
        return this.name;
    }

    initDataFromObject(data) {
        this.Id = data.Id;
        this.name = data.name;
    }
}