import React, { useState } from 'react';
import { LuClipboard, LuClipboardCheck } from 'react-icons/lu';

type Props = {
    input: string;
};

export default function CopyButton({ input }: Props) {
    const [copied, setCopied] = useState(false);
    function copyToClipboard() {
        setCopied(true);
        navigator.clipboard.writeText(input);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }
    return (
        <button className="copyButton" onClick={copyToClipboard}>
            {copied ? (
                <>
                    <LuClipboardCheck className="copyButton-icon tw-text-white" />
                </>
            ) : (
                <LuClipboard className="copyButton-icon tw-text-white" />
            )}
        </button>
    );
}
