import React, { Component } from 'react'

import Input from 'components/InputGroup/Input'
import Button from 'components/Button/Button'
import designInspirationDetailInterface from 'data/designInspiration/designInspirationDetailForm/designInspirationDetailInterface'
import functions from 'pages/AddLandingPage/functions'
import Navbar from 'components/Navbars/Navbar'

interface state{
    landingPageDetails: designInspirationDetailInterface;
}

export default class AddLandingPage extends Component<any, state> {
    constructor(props){
        super(props);

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            landingPageDetails : {} as designInspirationDetailInterface
        }
    }
    
    onChangeHandler(e) {
        let name = e.target.name;
        let value = e.target.value;
        

        this.setState((prevState) => ({
            landingPageDetails: {
                ...prevState.landingPageDetails,
                [name]: value,
            },
        }));

    }

    async onSubmit(e) {
        e.preventDefault();

        const AddLandingPageHandler = new functions();
        await AddLandingPageHandler.addLandingPage(this.state.landingPageDetails)
    }
    
  render() {
    return (
      <>
        {/* <GeneralNavbar /> */}
        <Navbar />
        <div className="alp-container">Add Landing Page</div>
            <section>
                <div className="Row content-center">
                    <div className="Column-6">
                        <form onSubmit={this.onSubmit}>
                            <Input
                                label="Title"
                                name="Productname"
                                onChange={this.onChangeHandler}
                                required = {1}
                            />

                            <Input
                                label="Description"
                                name="description"
                                onChange={this.onChangeHandler}
                                required = {1}
                            />

                            <Input
                                label="Web Image Link"
                                name="desktopViewImage"
                                onChange={this.onChangeHandler}
                                required = {1}
                            />

                            <Input
                                label="Mobile Image Link"
                                name="mobileViewImage"
                                onChange={this.onChangeHandler}
                                required = {1}
                            />

                            <Input
                                label="Preview Link"
                                name="websitelink"
                                onChange={this.onChangeHandler}
                                required = {1}
                            />

                            <Input
                                label="Category"
                                name="category"
                                onChange={this.onChangeHandler}
                                required={1}
                            />

                            <Button
                                id={undefined}
                                text="Submit"
                                className="btn btn-medium shadow-small btn-primary"
                                type="submit"
                            />

                        </form>
                    </div>
                </div>
                
            </section>
      </>
    )
  }
}
