import minUserFormInterface from "data/user/minUserForm/minUserFormInterface";
import minDesignFormInterface from "./minDesignFormInterface";

import minUserFormImpl from "data/user/minUserForm/minUserFormImpl";

export default class minDesignFormImpl implements minDesignFormInterface {
    Id: string;
    name: string;
    imageLink: string;
    views: string;
    isPremium: string;
    postedBy: minUserFormInterface;
    createdAt: string;
    updatedAt: string;

    getId() {
        return this.Id;
    }

    getname() {
        return this.name;
    }

    getimageLink() {
        return this.imageLink
    }

    getviews() {
        return this.views;
    }

    getisPremium() {
        return this.isPremium;
    }

    getpostedBy() {
        return this.postedBy;
    }

    getcreatedAt() {
        return this.createdAt;
    }

    getupdatedAt() {
        return this.updatedAt;
    }

    initDatafromObject(data) {
        this.Id = data.Id;
        this.name = data.name;
        this.imageLink = data.imageLink;
        this.views = data.views;
        this.isPremium = data.isPremium;
        this.postedBy = data.postedBy;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;

        this.postedBy = new minUserFormImpl();
        this.postedBy.initDatafromObject(data.postedBy);
    }
}