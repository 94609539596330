import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import designFormImpl from "data/design/designForm/designFormImpl";
import minDesignFormImpl from "data/design/minDesignForm/minDesignFormImpl";
import minDesignFormInterface from "data/design/minDesignForm/minDesignFormInterface";
import userFormImpl from "data/user/userForm/userFormImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";

class functions {
    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Profile;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl());
        return pageSEO;
    }

    async getDesignsbyUser(Id) {
        let params = {userId: Id}
        let result = await axiosCall(ApiRouteMapping.getDesignsbyUser.apiPath, "GET", params);
        const designs = new designFormImpl<minDesignFormInterface>();
        designs.initFromDataObject(result.data.designs.designs, minDesignFormImpl);
        return designs;
    }

    async getUserProfile() {
        let result = await axiosCall(ApiRouteMapping.getUser.apiPath, "GET");
        const user = new userFormImpl();
        user.initFromDataObject(result.data.user);
        return user;
    }

}

export default functions;