enum languageENUMS {
    HTML,
    CSS,
    JAVASCRIPT,
    JSX,
    PUG,
    JSONFORMATTER,
    TAILWIND,
}

export default languageENUMS;