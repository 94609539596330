import PrimaryLink from 'components/Button/PrimaryLink';
import Navbar from 'components/Navbars/Navbar';
import React from 'react';
import { templates } from 'utils/templates';

export default function Templates() {
    return (
        <>
            <Navbar />
            <div className="sb-hero tw-flex tw-pt-[120px] tw-items-center tw-pb-[145px]">
                <div className="tw-flex tw-flex-col tw-w-5/6 lg:tw-w-6/12 tw-mx-auto">
                    <div className="tw-mt-[15px] tw-flex tw-flex-col tw-items-center tw-justify-center">
                        <h1 className="tw-m-0 tw-p-0 tw-text-light tw-text-center tw-text-[35px] tw-leading-tight md:tw-text-[58px] tw-font-bold tw-text-black-light inter">
                            Releasing 30 Templates or every industry
                        </h1>
                        <p className="tw-mx-auto tw-mt-[20px] md:tw-w-2/3 tw-m-0 tw-p-0 tw-text-light/80 tw-text-center tw-leading-normal tw-text-base md:tw-text-[22px] tw-font-normal ">
                            Highly convertiable landing page templates for every
                            industry.
                            <br />
                        </p>
                    </div>
                    <div className="tw-flex tw-flex-col  md:tw-flex-row tw-items-center tw-justify-center tw-gap-4 md:tw-gap-2.5  tw-mt-6">
                        <PrimaryLink href="https://buy.stripe.com/cN216G2ZN1RYgQE8wP">
                            Pre Book @ $20
                        </PrimaryLink>
                        <PrimaryLink
                            variant="outline"
                            href="mailto:uday@buildfast.co.in"
                        >
                            Request a template
                        </PrimaryLink>
                    </div>
                    <div className="tw-mx-auto tw-mt-6">
                        <button
                            className="tw-text-blue tw-text-base tw-leading-[19.36px]"
                            onClick={() => {
                                window.location.href =
                                    'https://www.figma.com/community/file/1386988374596509935/high-quality-landing-page-templates';
                            }}
                        >
                            Checkout Templates -&gt;
                        </button>
                    </div>
                    {/* <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-gap-2.5 md:tw-gap-[12px] tw-mt-[25px]">
                        <div className="">
                            <img
                                className="object-cover"
                                src="https://ik.imagekit.io/codeui/CODE%20UI/Frame%2035778.svg?updatedAt=1716027701808"
                                alt=""
                            />
                        </div>
                        <div className="tw-flex tw-flex-col ">
                            <img
                                src="https://ik.imagekit.io/codeui/CODE%20UI/Frame%2035788.svg?updatedAt=1716027700072"
                                className="tw-h-[15px] md:tw-w-1/2 "
                                alt=""
                            />
                            <p className="tw-p-0 tw-m-0 tw-text-sm tw-font-medium tt-hoves-pro">
                                Loved by Founders Worldwide
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="tw-grid-responsive tw-grid tw-container tw-grid-cols-4 tw-mx-auto"
            >
                {templates.map((template) => (
                    <a
                        href={`/templates/${template.id}`}
                        key={template.id}
                        className="templates-card"
                    >
                        <img className="" src={template.designImage} alt="" />
                        <div className="templates-card-text">
                            <h4>{template.title}</h4>
                            <p>21 June 2024</p>
                        </div>
                    </a>
                ))}
            </div>
            <div className="tw-flex tw-gap-4 tw-w-5/6  tw-items-center tw-justify-center lg:tw-w-10/12 tw-mx-auto tw-h-full">
                <iframe
                    title="Figma Templates"
                    style={{ border: '1px solid rgba(0, 0, 0, 0.1);' }}
                    width="800"
                    height="450"
                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fdesign%2FZJIQ8tMUWL4hyVOO58E8w9%2FCodeUI-Templates%3Fnode-id%3D0-1%26t%3DJg1F8FD4sWZgcczk-1"
                    allowFullScreen
                ></iframe>
            </div>
        </>
    );
}
